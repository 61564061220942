import React, { Component } from 'react';

//Components
import InstructorActivity from './instructor_activity.js';
import InstructorMessages from './instructor_messages.js';
import InstructorForum from './instructor_forum.js';

class InstructorRow extends Component{
  render(){

    return(
      <tr>
        <td>{this.props.instructor.name}</td>
        <InstructorActivity instructor={this.props.instructor} course={this.props.course} layout={this.props.layout} />
        <InstructorMessages instructor={this.props.instructor} course={this.props.course} layout={this.props.layout} />
        <InstructorForum instructor={this.props.instructor} course={this.props.course} layout={this.props.layout} />
      </tr>
    )
  }
}

export default InstructorRow;
