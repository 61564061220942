import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopupboxManager } from 'react-popupbox';
import axios from 'axios';
import Moment from 'react-moment';

class Mail extends Component {
  constructor(props){
    super(props);

    this.state ={
      unread : null,
      interval : false
    }

    this.getUnread = this.getUnread.bind(this);
    this.openPopupbox = this.openPopupbox.bind(this);
  }

  getUnread(){
    var link = '/api/topbar/get_unread.php';
    axios.get(link)
      .then(function (response) {
        this.setState({
          unread : response.data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error.response.data);
        console.log(error.response.status);
        if(error.response.status === 401){
          setTimeout(this.getUnread, 100);
        }
      }.bind(this));
  }

  openPopupbox(){
    let c = 0;
    const content = (
      <React.Fragment>
        <div className="popup-content">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Course</th>
                <th>Subject</th>
                <th>Participants</th>
                <th>Last Message Received</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.unread.map((item) => {
                  return(
                    <tr key={item.id}>
                      <td>{item.context_name}</td><td>{item.subject}</td><td>
                          { item.participants.map((p, i) => {
                            if(p.id === this.props.user.id || (i - c) > 3){
                              c = 1;
                              return(<React.Fragment />);
                            }else if((i - c) === 3){
                              return(
                                <span key={p.id}> ...and {item.participants.length - 3} others</span>
                              )
                            }else if((i - c) > 0){
                              return(
                                <span key={p.id}>, {p.name}</span>
                              )
                            }
                            return(
                              <span key={p.id}>{p.name}</span>
                            )
                          })}
                      </td><td><Moment format="M/DD/YYYY h:mm:ss a">{item.last_message_at}</Moment></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <div className="popup-footer">
          <a href="https://cpeonline.ucdavis.edu/conversations" target="_blank" rel="noopener noreferrer">Go to Canvas Inbox</a>
        </div>
      </React.Fragment>
    )
    const config ={
      titleBar: {
        enable: true,
        text: "Unread Canvas Messages"
      },
      fadeIn: true,
      fadeInSpeed: 500
    }
    PopupboxManager.open({ content, config });
  }

  componentDidMount(){
    this.getUnread();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.unread !== null && this.state.interval === false){
      this.setState({
        interval : true
      });
      setInterval(this.getUnread, 300000);
    }
  }

  render(){
    let mail = '';
    if(this.state.unread !== null && this.state.unread.length !== 0){
      mail = <React.Fragment>
                <FontAwesomeIcon icon="envelope" onClick={this.openPopupbox}/>
                <span className="notification hidden-sm hidden-xs">{this.state.unread.length}</span>
              </React.Fragment>
    }else{
      mail = <FontAwesomeIcon icon="envelope-open" />
    }
    return(
      <li className="nav-item">
        <span className="nav-link">
          {mail}
        </span>
      </li>
    )
  }

}

export { Mail };
