import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './../loading.js';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';

class InstructorActivity extends Component{
  constructor(props){
    super(props);

    this.state = {
      unread_grade : null,
      oldest_grade : null,
      unread_ungrade : null,
      oldest_ungrade : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getDiscussions = this.getDiscussions.bind(this);
  }

  getDiscussions(){
    var link = '/api/reports/dashboard/instructor/get_forums.php';
    if(this.props.course.workflow_state === undefined){
      this.setState({
        unread_grade : "Course Still Pending",
        oldest_grade : "Course Still Pending",
        unread_ungrade : "Course Still Pending",
        oldest_ungrade : "Course Still Pending"
      })
      return;
    }
    var data = {
      user: this.props.instructor.id,
      course: this.props.course.id,
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let unread_ungrade = 0;
        let oldest_ungrade = moment();
        let unread_grade = 0;
        let oldest_grade = moment();

        response.data.forEach(function(e){
          if(e.assignment_id !== null){
            unread_grade += e.unread_count;
            oldest_grade = this.checkReplies(e.posts.view, oldest_grade, e.posts.unread_entries)
          }else{
            unread_ungrade += e.unread_count;
            oldest_ungrade = this.checkReplies(e.posts.view, oldest_ungrade, e.posts.unread_entries)
          }
        }.bind(this))

        if(moment().isSame(oldest_ungrade, 'day')){
          oldest_ungrade = "All Read";
        }

        if(moment().isSame(oldest_grade, 'day')){
          oldest_grade = "All Read";
        }

        this.setState({
          unread_ungrade,
          oldest_ungrade,
          unread_grade,
          oldest_grade
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  checkReplies(posts, oldest, unread){
    posts.forEach(i => {
      if(unread.includes(i.id) && moment(i.updated_at).isBefore(oldest)){
        oldest = moment(i.updated_at);
      }

      if(i.replies !== undefined && i.replies.length > 0){
        oldest = this.checkReplies(i.replies, oldest, unread)
      }

    });

    return oldest

  }

  componentDidMount(){
    this.getDiscussions();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }

  render(){
    let overall_forum_status = <Loading />;
    let overall_grade_status = <Loading />;
    let unread_grade_status = <Loading />;
    let oldest_grade_status = <Loading />;
    let overall_ungrade_status = <Loading />;
    let unread_ungrade_status = <Loading />;
    let oldest_ungrade_status = <Loading />;

    if(this.props.course.workflow_state === undefined){
      overall_forum_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
      overall_grade_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
      unread_grade_status = <React.Fragment>Course Still Pending <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
      oldest_grade_status = <React.Fragment>Course Still Pending <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
      overall_ungrade_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
      unread_ungrade_status = <React.Fragment>Course Still Pending <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
      oldest_ungrade_status = <React.Fragment>Course Still Pending <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
    }else{
      let now = new moment();

      let graded_duration;
      if(this.state.oldest_grade !== 'All Read'){
        graded_duration = now.diff(moment(this.state.oldest_grade), 'days');
      }

      let ungraded_duration;
      if(this.state.oldest_ungrade !== 'All Read'){
        ungraded_duration = now.diff(moment(this.state.oldest_ungrade), 'days');
      }

      if(this.state.unread_grade !== null){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
        overall_grade_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
        unread_grade_status = <React.Fragment>{this.state.unread_grade} <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
      }

      if(this.state.unread_ungrade !== null){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
        overall_ungrade_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
        unread_ungrade_status = <React.Fragment>{this.state.unread_ungrade} <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
      }

      if(this.state.oldest_grade !== null){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
        overall_grade_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
        if(this.state.oldest_grade !== 'All Read'){
          oldest_grade_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest_grade)}</Moment> <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
        }else{
          oldest_grade_status = <React.Fragment>{this.state.oldest_grade} <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
        }
      }

      if(this.state.oldest_ungrade !== null){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
        overall_ungrade_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
        if(this.state.oldest_ungrade !== 'All Read'){
          oldest_ungrade_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest_ungrade)}</Moment> <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
        }else{
          oldest_ungrade_status = <React.Fragment>{this.state.oldest_ungrade} <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
        }
      }

      if(this.state.unread_grade > 3){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
        overall_grade_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
        unread_grade_status = <React.Fragment>{this.state.unread_grade} <FontAwesomeIcon icon="circle" color="#FF9500" /></React.Fragment>;
      }

      if(this.state.unread_ungrade > 3){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
        overall_ungrade_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
        unread_ungrade_status = <React.Fragment>{this.state.unread_ungrade} <FontAwesomeIcon icon="circle" color="#FF9500" /></React.Fragment>;
      }

      if(this.state.oldest_grade !== 'All Read' && graded_duration > 3){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
        overall_grade_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
        oldest_grade_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest_grade)}</Moment> <FontAwesomeIcon icon="circle" color="#FF9500" /></React.Fragment>;
      }

      if(this.state.oldest_ungrade !== 'All Read' && ungraded_duration > 3){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
        overall_ungrade_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
        oldest_ungrade_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest_ungrade)}</Moment> <FontAwesomeIcon icon="circle" color="#FF9500" /></React.Fragment>;
      }

      if(this.state.unread_grade > 6){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
        overall_grade_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
        unread_grade_status = <React.Fragment>{this.state.unread_grade} <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>;
      }

      if(this.state.unread_ungrade > 6){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
        overall_ungrade_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
        unread_ungrade_status = <React.Fragment>{this.state.unread_ungrade} <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>;
      }

      if(this.state.oldest_grade !== 'All Read' && graded_duration > 6){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
        overall_grade_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
        oldest_grade_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest_grade)}</Moment> <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>;
      }

      if(this.state.oldest_ungrade !== 'All Read' && ungraded_duration > 6){
        overall_forum_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
        overall_ungrade_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
        oldest_ungrade_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest_ungrade)}</Moment> <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>;
      }
    }

    return(
      <React.Fragment>
        <td className={((this.props.layout.w < 8) ? 'd-print-none' : 'd-none')}>{overall_forum_status}</td>
        <td className={((this.props.layout.w > 7 && this.props.layout.w < 12) ? 'd-print-none' : 'd-none')}>{overall_grade_status}</td>
        <td className={((this.props.layout.w < 12) ? 'd-none d-print-table-cell' : '')}>{unread_grade_status}</td>
        <td className={((this.props.layout.w < 12) ? 'd-none d-print-table-cell' : '')}>{oldest_grade_status}</td>
        <td className={((this.props.layout.w > 7 && this.props.layout.w < 12) ? 'd-print-none' : 'd-none')}>{overall_ungrade_status}</td>
        <td className={((this.props.layout.w < 11) ? 'd-none d-print-table-cell' : '')}>{unread_ungrade_status}</td>
        <td className={((this.props.layout.w < 11) ? 'd-none d-print-table-cell' : '')}>{oldest_ungrade_status}</td>
      </React.Fragment>
    )
  }
}

export default InstructorActivity;
