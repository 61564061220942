import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoStudents from '../special/no_students.js';
import Loading from '../loading.js';
import Student from './roster_item.js';

class RosterReport extends Component {

  componentDidMount(){
    let footer = (
      <div>
        <FontAwesomeIcon icon="circle" color="#87CB16"/> Access Course within 3 days | <FontAwesomeIcon icon="circle" color="#00B5E2"/> No Access Within 3 days | <FontAwesomeIcon icon="circle" color="#FFCD00"/> No Access Within 7 days | <FontAwesomeIcon icon="circle" color="#FF4A55"/> No Access Within 14 days | <FontAwesomeIcon icon="circle" color="#ED8B00"/> Pending | <FontAwesomeIcon icon="circle" color="#000000"/> Withdrawn | <FontAwesomeIcon icon="circle" color="#4286f4"/> Concluded
      </div>
    )
    this.props.setFooter(footer)
  }

  render(){
    if(this.props.status === undefined || this.props.status === null){
      return <Loading />
    }else if(this.props.enrollments.length > 0){
      return (
        <table className="table table-striped">
          <thead>
            <tr>
              <th className={((this.props.layout.w < 8) ? 'd-none d-print-table-cell' : '')}>Profile Image</th>
              <th>Name</th>
              <th className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>TUBS ID</th>
              <th>Destiny ID</th>
              <th className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>Email Address</th>
              <th>Status</th>
            </tr>
          </thead>
          <tfoot>
            <th colspan={((this.props.layout.w < 5) ? '3' : ((this.props.layout.w < 7) ? '4' : ((this.props.layout.w < 8) ? '5' : '6')))}>
              Total: {this.props.enrollments.length}
            </th>
          </tfoot>
          <tbody>
            {this.props.enrollments.map((item) => {
              return(
                <Student data={item} layout={this.props.layout} />
              )
            })}
          </tbody>
        </table>
      )
    }else{
      return <NoStudents />
    }
  }

}

export default RosterReport;
