import React, { Component } from 'react';
import EmailRow from './email_row.js';
import NoCourse from '../special/no_course.js';

class DestinyEmail extends Component {
  constructor(props){
    super(props);

    this.state = {
      search : ''
    }

    this.updateSearch = this.updateSearch.bind(this);
  }

  updateSearch(evt) {
    this.setState({
      search : evt.target.value
    })
  }

  componentDidMount(){
    let footer = (
      <div>
      </div>
    )
    this.props.setFooter(footer)
  }

  render(){
    if(this.props.courses.length === 0){
      return (
        <NoCourse />
      )
    }else{
      return(
        <React.Fragment>
          <form>
            <label for="search">Search</label>
            <input onChange={this.updateSearch} type="text" class="form-control" id="search" placeholder="Text to Search" />
          </form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Project Code</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {this.props.courses.map((item) => {
                return(
                  <EmailRow course={item} layout={this.props.layout} search={this.state.search}/>
                )
              })}
            </tbody>
          </table>
        </React.Fragment>
      )
    }
  }
}

export default DestinyEmail;
