import React, { Component } from 'react';

class Footer extends Component{
  render(){
    return(
      <footer className="footer">
          <div className="container">
              <nav>
                  <ul className="footer-menu">
                  </ul>
                  <p className="copyright text-center">
                      ©
                      <script>
                          document.write(new Date().getFullYear())
                      </script>
                      UC Davis Continuing and Professional Education
                  </p>
              </nav>
          </div>
      </footer>
    )
  }
}

export { Footer }
