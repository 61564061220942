import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../loading.js';
import NoAnnouncements from '../special/no_announcements.js';
import AnnouncementItem from './announcement_item.js';
import axios from 'axios';

class AnnouncementReport extends Component {
  constructor(props){
    super(props);

    this.state = {
      announcements: [],
      loaded: false,
      cancelToken : axios.CancelToken.source()
    }

    this.getAnnouncements = this.getAnnouncements.bind(this);
  }

  getAnnouncements(){
    var link = '/api/reports/active/get_announcements.php';
    var data = {
      id: this.props.course.id
    };

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          announcements: response.data,
          loaded: true
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    let footer = (
      <div>
        <FontAwesomeIcon icon="circle" color="#87CB16"/> Published | <FontAwesomeIcon icon="circle" color="#FF4A55"/> Unpublished
      </div>
    )
    this.props.setFooter(footer)
    this.getAnnouncements();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.course_code !== this.props.course.course_code){
      this.setState({
        anncouncement : [],
        loaded: false
      }, () => this.getAnnouncements());
    }
  }

  render(){
    if(this.state.loaded === false){
      return <Loading />
    }else if(this.state.announcements.length === 0){
      return <NoAnnouncements />
    }else{
      return (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>Read</th>
              <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Unread</th>
              <th>Posted</th>
              <th>Published</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.announcements.map((item) => {
                return <AnnouncementItem announcement={item} course={this.props.course} layout={this.props.layout} />
              })
            }
          </tbody>
        </table>
      )
    }
  }
}

export default AnnouncementReport;
