import React, { Component } from 'react';
import Member from './course_members_item.js'
import Loading from '../loading.js';
import NoInstructor from '../special/no_instructor.js';
import NoContacts from '../special/no_contacts.js';

class CourseMembers extends Component {
  render(){
    return (
      <div>
        <div className={((this.props.layout.w < 6) ? '' : 'div-float-left')}>
          <h3>Instructors</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.instructors.map((item) =>{
                  return(
                    <Member user={item} />
                  )
                })
              }
              {
                this.props.instructors.length === 0 && this.props.instructor_status !== null && this.props.instructor_status !== undefined &&
                  <NoInstructor />
              }
              {
                this.props.instructors.length === 0 && (this.props.instructor_status === null || this.props.instructor_status === undefined) &&
                  <Loading />
              }
            </tbody>
          </table>
        </div>
        <div className={((this.props.layout.w < 6) ? '' : 'div-float-left')}>
          <h3>Section Contacts</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.pas.map((item) =>{
                  return(
                    <Member user={item} />
                  )
                })
              }
              {
                this.props.pas.length === 0 && this.props.pa_status !== null && this.props.pa_status !== undefined &&
                  <NoContacts />
              }
              {
                this.props.pas.length === 0 && (this.props.pa_status === null || this.props.pa_status === undefined) &&
                  <Loading />
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

export default CourseMembers;
