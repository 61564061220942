import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../loading.js';
import axios from 'axios';

class CourseSurveys extends Component {
  constructor(props){
    super(props)

    this.state = {
      surveys : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getSurveys = this.getSurveys.bind(this);
  }

  getSurveys(){
    var link = '/api/reports/course/get_surveys.php';
    let  data = {
      course: this.props.course.course_code
    };

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          surveys: response.data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    this.getSurveys();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.id !== this.props.course.id){
      this.setState({
        surveys : null
      }, () => this.getSurveys())
    }
  }

  render(){
    return(
      <div>
        <h3>Course Surveys</h3>
        {(this.state.surveys === null) ? <Loading /> :
          (this.state.surveys.constructor === Array) ?
            <table className="table">
              <tr><th>Title</th><th>Type</th><th>Responses</th><th>Availability</th></tr>
              {this.state.surveys.map(e => <tr>
                                            <td>{e.m_sTitle}</td>
                                            <td>{(e.m_cType === 'o') ? <span>Online</span> : (e.m_cType === 'c') ? <span>Hard Copy <FontAwesomeIcon icon="exclamation-triangle" color="yellow"/></span> : <span>Unknown <FontAwesomeIcon icon="exclamation-circle" color="red"/></span>}</td>
                                            <td>{e.m_nFormCount}</td>
                                            <td>{(e.m_nOpenState) ? <span>Open</span> : <span>Closed</span>}</td>
                                          </tr>)}
            </table>
          :
          <span>{this.state.surveys}</span>
         }
      </div>
    )
  }
}

export default CourseSurveys;
