import React, { Component } from 'react';
import Profile from './student_profile.js';
import Assignments from './student_assignment.js';
import axios from 'axios';

class StudentItem extends Component {
  constructor(props){
    super(props);

    this.state = {
      student : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getStudent = this.getStudent.bind(this);
  }

  getStudent(){
    var link = '/api/reports/pending/get_user.php';
    var data = {
      id: this.props.enrollment.user.id
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          student : response.data,
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentWillMount(){
    this.getStudent();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }


  render(){
    return (
      <div>
        <Profile student={this.state.student} enrollment={this.props.enrollment} layout={this.props.layout} />
        <Assignments course={this.props.course} student={this.state.student} enrollment={this.props.enrollment} layout={this.props.layout} />
      </div>
    )
  }
}

export default StudentItem;
