import React, { Component } from 'react';

class CourseReport extends Component {
  render(){
    if(this.props.grades === undefined || this.props.grades === null){
      return <React.Fragment />
    }
    let prev = '100%'
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Letter/Mark</th>
            <th>Range</th>
          </tr>
        </thead>
        <tbody>
          {this.props.grades.map((item) => {
            let p = prev;
            prev = '< ' + item.value*100 + '%';
            return(
              <tr>
                <td>{item.name}</td>
                <td>{p + ' - ' + item.value*100 + '%'}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

}

export default CourseReport;
