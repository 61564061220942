import React, { Component } from 'react';
import GradesTable from './course_grades_table.js'
import Loading from '../loading.js';
import axios from 'axios';

class CourseGrades extends Component {
  constructor(props){
    super(props);

    this.state = {
      canvas : null,
      destiny : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getData = this.getData.bind(this);
    this.getCanvasScheme = this.getCanvasScheme.bind(this);
    this.getPendingScheme = this.getPendingScheme.bind(this);
    this.getDestinyScheme = this.getDestinyScheme.bind(this);
  }

  getData(){
    if(this.props.type !== 'pending'){
      this.getCanvasScheme();
    }else{
      this.getPendingScheme();
    }
    this.getDestinyScheme();
  }

  getCanvasScheme(){
    var link = '/api/reports/course/get_canvas_scheme.php';
    var data = {
      id: this.props.course.grading_standard_id
    };

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          canvas : response.data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getPendingScheme(){
    var link = '/api/reports/course/get_pending_scheme.php';
    var data = {
      scheme: this.props.course.validgrades
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          canvas : response.data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getDestinyScheme(){
    var link = '/api/reports/course/get_destiny_scheme.php';
    var data = {
      code: this.props.course.course_code
    };

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          destiny : response.data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    this.getData();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.id !== this.props.course.id){
      this.setState({
        canvas : null,
        destiny : null
      }, () => this.getData())
    }
  }

  render(){
    if(this.props.type === 'pending'){
      return (
        <div>
        <h3>Grade Information</h3>
        <table>
          <tr>
            <td>Canvas Scheme: {(this.state.canvas === null) && <Loading inline={true} />}{(this.state.canvas !== null) && this.state.canvas}</td>
            <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>Destiny Scheme: {(this.state.destiny === null) && <Loading inline={true} />}{(this.state.destiny !== null) && this.state.destiny.type}</td>
          </tr>
        </table>
        </div>
      )
    }else{
      return (
        <div>
        <h3>Grade Information</h3>
        <table>
          <tr>
            <td>Canvas Scheme: {(this.state.canvas === null) && <Loading inline={true} />}{(this.state.canvas !== null) && this.state.canvas.title}</td>
            <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>Destiny Scheme: {(this.state.destiny === null) && <Loading inline={true} />}{(this.state.destiny !== null) && this.state.destiny.type}</td>
          </tr>
          <tr className={((this.props.layout.w < 8) ? 'd-none d-print-table-row' : '')}>
            <td colspan='2'>{(this.state.canvas === null) && <Loading />}{(this.state.canvas !== null) && <GradesTable grades={this.state.canvas.grading_scheme} />}</td>
          </tr>
        </table>
        </div>
      )
    }
  }

}

export default CourseGrades;
