import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../loading.js';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';

class CourseReport extends Component {
  constructor(props){
    super(props);

    this.state = {
      read: <Loading />,
      unread: <Loading />,
      loaded: false,
      cancelToken : axios.CancelToken.source()
    }

    this.getAnnouncements = this.getAnnouncements.bind(this);
  }

  getAnnouncements(){
    var link = '/api/reports/active/get_announcement_unread.php';
    var data = {
      id: this.props.announcement.id,
      course: this.props.course.id
    };

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          read: response.data.read,
          unread: response.data.unread,
          loaded: true
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    this.getAnnouncements();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.course_code !== this.props.course.course_code){
      this.setState({
        anncouncement : [],
        loaded: false
      }, () => this.getAnnouncements());
    }
  }

  render(){
    return (
      <tr>
        <td>{this.props.announcement.title}</td>
        <td className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>{this.state.read}</td>
        <td className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>{this.state.unread}</td>
        <td><Moment format="M/DD/YYYY h:mm A">{moment(this.props.announcement.posted_at)}</Moment></td>
        <td>{((this.props.announcement.published === true) ? <FontAwesomeIcon icon="circle" color="#87CB16"/> : <FontAwesomeIcon icon="circle" color="#FF4A55"/>)}</td>
      </tr>
    )
  }

}

export default CourseReport;
