import React, { Component } from 'react';
import Loading from './../loading.js';
import axios from 'axios';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import StudentAssignmentItem from './student_assignment_item.js';

class StudentAssignments extends Component {
  constructor(props){
    super(props);

    this.state = {
      loaded : false,
      innerColors : ['#87CB16', '#4286f4','#FF9500', '#FF4A55'],
      outerColors : ['#87CB16','#FF9500', '#FF4A55'],
      cancelToken : axios.CancelToken.source(),
      submissions : []
    }

    this.getSubmissions = this.getSubmissions.bind(this);
    this.customizedLabel = this.customizedLabel.bind(this);
  }

  getSubmissions(){
    var link = '/api/reports/active/get_user_submissions.php';
    var data = {
      user: this.props.enrollment.user.id,
      course: this.props.course.id
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        console.log(response);
        let assignments = response.data.assignments;
        let submissions = response.data.submissions;
        let late = 0;
        let submitted = 0;
        let unsubmitted = 0;
        let unsub_late = 0;
        let graded = 0;
        let ungraded = 0;

        for(let i = 0; i < submissions.length; i++){
          if(submissions[i].workflow_state === 'unsubmitted'){
            if(submissions[i].late){
              unsub_late++;
            }else{
              unsubmitted++;
            }
          }else{
            if(submissions[i].late){
              late++;
            }else{
              submitted++;
            }

            if(submissions[i].workflow_state === 'graded'){
              graded++;
            }else if(submissions[i].workflow_state === 'submitted'){
              ungraded++;
            }
          }
        }

        let innerData = [
          {name: "Submitted", value: submitted},
          {name: "Submitted Late", value: late},
          {name: "Unsubmitted", value: unsubmitted},
          {name: "Late", value: unsub_late}
        ]

        let outerData = [
          {name: "Graded", value: graded},
          {name: "Ungraded", value: ungraded},
          {name: "Unsubmitted", value: unsubmitted + unsub_late}
        ]

        this.setState({
          assignments,
          submissions,
          innerData,
          outerData,
          loaded: true
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  customizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }){
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * .5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  componentWillMount(){
    this.getSubmissions();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }

  render(){
    if(this.state.loaded){
      console.log(this.state.innerData);
      return(
        <div className={((this.props.layout.w < 8) ? 'student-report-full' : ((this.props.layout.w < 11) ? 'student-report-half' : 'student-report-third'))}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={this.state.innerData} startAngle={180} endAngle={0} cy={'90%'} outerRadius={120} fill="#8884d8" labelLine={false} label={this.customizedLabel}>
                {
                  this.state.innerData.map((entry, index) => <Cell fill={this.state.innerColors[index % this.state.innerColors.length]}/>)
                }
              </Pie>
              <Pie data={this.state.outerData} startAngle={180} endAngle={0} cy={'90%'} innerRadius={130} outerRadius={150} fill="#8884d8" label>
                {
                  this.state.outerData.map((entry, index) => <Cell fill={this.state.outerColors[index % this.state.outerColors.length]}/>)
                }
              </Pie>
              <Legend />
            </PieChart>
          </ResponsiveContainer>
          <div className={((this.props.layout.h < 5) ? 'd-none d-print' : 'assignment-report-table')}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Due Date</th>
                <th>Submitted</th>
                <th>Graded</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.assignments.map((item)=>{
                  return <StudentAssignmentItem assignment={item} submissions={this.state.submissions} />
                })
              }
            </tbody>
          </table>
          </div>
        </div>
      )
    }else{
      return (
        <div className={((this.props.layout.w < 8) ? 'student-report-full' : ((this.props.layout.w < 11) ? 'student-report-half' : 'student-report-third'))}>
          <Loading />
        </div>
      )
    }
  }
}

export default StudentAssignments;
