import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import Loading from '../loading.js';
import axios from 'axios';

class CourseDates extends Component {
  constructor(props){
    super(props)

    this.state = {
      start : null,
      end : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getDates = this.getDates.bind(this);
  }

  getDates(){
    var link = '/api/reports/course/get_dates.php';
    var data = {
      code: this.props.course.course_code
    };

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          start: response.data.start_date,
          end: response.data.end_date
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    this.getDates();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.id !== this.props.course.id){
      this.setState({
        start : null,
        end : null
      }, () => this.getDates())
    }
  }

  render(){
    if(this.props.type === 'pending'){
      return (
        <div>
          <h3>Date Information</h3>
          <table>
            <tr>
              <td>Canvas Start Date: <Moment format="M/DD/YYYY">{moment(this.props.course.startdate, "X")}</Moment></td>
              <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>Canvas Start Date: <Moment format="M/DD/YYYY">{moment(this.props.course.enddate, "X")}</Moment></td>
              <td className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>Access Length: {moment(this.props.course.enddate, "X").diff(moment(this.props.course.startdate, "X"), 'weeks')} Weeks</td>
            </tr>
            <tr>
              <td>Destiny Start Date: {(this.state.start === null) && <Loading inline={true} />}{(this.state.start !== null) && <Moment format="M/DD/YYYY">{moment(this.state.start, 'X')}</Moment>}</td>
              <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>Destiny End Date: {(this.state.end === null) && <Loading inline={true} />}{(this.state.end !== null) && <Moment format="M/DD/YYYY">{moment(this.state.end, 'X')}</Moment>}</td>
              <td className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>Access Length: {(this.state.start === null && this.state.end === null) && <Loading inline={true} />}{(this.state.start !== null && this.state.end !== null) && moment(this.state.end, 'X').diff(moment(this.state.start, 'X'), 'weeks')} Weeks</td>
            </tr>
          </table>
        </div>
      )
    }else{
      return (
        <div>
          <h3>Date Information</h3>
          <table>
            <tr>
              <td>Canvas Start Date: <Moment format="M/DD/YYYY">{moment(this.props.course.start_at)}</Moment></td>
              <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>Canvas Start Date: <Moment format="M/DD/YYYY">{moment(this.props.course.end_at)}</Moment></td>
              <td className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>Access Length: {moment(this.props.course.end_at).diff(moment(this.props.course.start_at), 'weeks')} Weeks</td>
            </tr>
            <tr>
              <td>Destiny Start Date: {(this.state.start === null) && <Loading inline={true} />}{(this.state.start !== null) && <Moment format="M/DD/YYYY">{moment(this.state.start, 'X')}</Moment>}</td>
              <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>Destiny End Date: {(this.state.end === null) && <Loading inline={true} />}{(this.state.end !== null) && <Moment format="M/DD/YYYY">{moment(this.state.end, 'X')}</Moment>}</td>
              <td className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>Access Length: {(this.state.start === null && this.state.end === null) && <Loading inline={true} />}{(this.state.start !== null && this.state.end !== null) && moment(this.state.end, 'X').diff(moment(this.state.start, 'X'), 'weeks')} Weeks</td>
            </tr>
          </table>
        </div>
      )
    }
  }

}

export default CourseDates;
