//Libraries
import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar';
import { PopupboxContainer } from 'react-popupbox';
import { PopupboxManager } from 'react-popupbox';

//Personal Libraries
import { Loading } from './global/loading.js';

//Page Components
import { SideBar } from "./global/sidebar.js";
import { TopBar } from "./global/topbar.js";
import { Footer } from "./global/footer.js";

//Report Pages
import { Dashboard } from "./reports/dashboard.js"

//CSS
import "./css/carousel.min.css";
import './css/bootstrap.min.css';
import './css/light-bootstrap-dashboard.css';
import './css/react-popupbox.css';
import './css/nprogress.css';
import './css/grid.css';
// import './css/react-dd-menu/react-dd-menu.scss';
import './css/App.css';

//Add Font Libraries
library.add(fas, fab, far);

loadProgressBar();

class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      user : null,
      activeId : 0,
      activeIndex : 0,
      activeType : 'dashboard',
      name : 'Dashboard',
      content : '',
      code : null,
      quarters : null,
      activeQuarter : 1,
      active : null,
      pending : null,
      concluded : null,
      adminMode : false,
      accounts : null,
      activeAccount : null,
      stage : "start"
    };

    this.handleClick = this.handleClick.bind(this);
    this.setAdmin = this.setAdmin.bind(this);
    this.resetUser = this.resetUser.bind(this);
    this.getCourses = this.getCourses.bind(this);
    this.getActiveCourses = this.getActiveCourses.bind(this);
    this.getPendingCourses = this.getPendingCourses.bind(this);
    this.getConcludedCourses = this.getConcludedCourses.bind(this);
    this.getAccounts = this.getAccounts.bind(this);
    this.updateQuarter = this.updateQuarter.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.sortByCourseCode = this.sortByCourseCode.bind(this);
    this.openPopupbox = this.openPopupbox.bind(this);
    this.updatePopupbox = this.updatePopupbox.bind(this);
    this.refreshKey = this.refreshKey.bind(this);
    this.filterPending = this.filterPending.bind(this);
  }

  //Data Retreival Methods
  getKey(){
    var link = '/api/global/get_key.php';
    var data = {
      code: this.state.code
    };
    axios.post(link, data)
      .then(function (response) {
        for(let i = 0; i < response.data.layout.length; i++){
            response.data.layout[i].x = parseInt(response.data.layout[i].x);
            response.data.layout[i].y = parseInt(response.data.layout[i].y);
            response.data.layout[i].w = parseInt(response.data.layout[i].w);
            response.data.layout[i].h = parseInt(response.data.layout[i].h);
            response.data.layout[i].minW = parseInt(response.data.layout[i].minW);
            response.data.layout[i].minH = parseInt(response.data.layout[i].minH);
        };
        this.setState({
          user : response.data.user,
          expires : response.data.expires_in,
          stage : "quarters",
          layout : response.data.layout,
          report : response.data.report,
          db : response.data.db
        })
        setTimeout(this.refreshKey, (response.data.expires_in * 1000)/2)
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  refreshKey(){
    var link = '/api/global/refresh_key.php';
    var data = {
      code: this.state.code
    };
    axios.post(link, data)
      .then(function (response) {
        this.setState({
          expires : response.data
        })
        setTimeout(this.refreshKey, (response.data * 1000)/2)
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  sortByCourseCode(a, b){
    if(a.course_code < b.course_code){
      return -1;
    }else if(a.course_code > b.course_code){
      return 1;
    }
    return 0;
  }

  getQuarters(){
    var link = '/api/global/get_quarters.php';
    var data = {
      user: this.state.user.id
    };
    axios.post(link, data)
      .then(function (response) {
        var regex = /^\d{3}\s-\s\w{4,6}\s\d{4}$/i;
        var quarters = response.data.terms.filter( function(term){
          return regex.test(term.name);
        })
        quarters = quarters.reverse();
        this.setState({
          quarters,
          activeQuarter: response.data.active,
          quarterCode: response.data.activeCode,
          stage : 'accounts'
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getAccounts(){
    var link = '/api/global/get_accounts.php';
    axios.get(link)
      .then(function (response) {
        if(response.data.length === 0){
          this.setState({
            accounts : -1,
            activeAccount: -1,
            stage : 'courses'
          })
        }else{
          this.setState({
            accounts : response.data,
            activeAccount: response.data[0].id,
            stage : 'courses'
          })
        }
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getCourses(){
    this.getActiveCourses();
    this.getPendingCourses();
    this.getConcludedCourses();
  }

  getActiveCourses(){
    var link = '/api/global/get_active_courses.php';
    var data = {
      quarter: this.state.activeQuarter,
      mode: this.state.adminMode,
      account: this.state.activeAccount
    };
    axios.post(link, data)
      .then(function (response) {
        let active = response.data;
        if(active.length > 1){
          active.sort(this.sortByCourseCode);
        }
        this.setState({
          active
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getPendingCourses(){
    var link = '/api/global/get_pending_courses.php';
    var data = {
      quarter: this.state.quarterCode,
      mode: this.state.adminMode,
      account: this.state.activeAccount,
      user: this.state.user.id
    };
    axios.post(link, data)
      .then(function (response) {
        let pending = response.data;
        if(pending.length > 1){
          pending.sort(this.sortByCourseCode);
        }
        this.setState({
          pending
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getConcludedCourses(){
    var link = '/api/global/get_concluded_courses.php';
    var data = {
      quarter: this.state.activeQuarter,
      mode: this.state.adminMode,
      account: this.state.activeAccount
    };
    axios.post(link, data)
      .then(function (response) {
        let concluded = response.data;
        if(concluded.length > 1){
          concluded.sort(this.sortByCourseCode);
        }
        this.setState({
          concluded
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  //Data Manipualtion
  updateAccount(activeAccount){
    this.setState({
      activeAccount,
      active : null,
      pending : null,
      concluded : null,
      stage : 'courses',
      activeId: 0,
      name: 'Dashboard',
      activeIndex: 0,
      activeType: 'dashboard'
    })
  }

  updateQuarter(activeQuarter, quarterCode){
    this.setState({
      activeQuarter,
      quarterCode,
      active : null,
      pending : null,
      concluded : null,
      stage : 'courses',
      activeId: 0,
      name: 'Dashboard',
      activeIndex: 0,
      activeType: 'dashboard'
    })
  }

  resetUser(evt){
    evt.preventDefault();
    this.getNav();
  }

  handleClick(newId, newName, newIndex, newType){
    this.setState({
      activeId : newId,
      name : newName,
      activeIndex : newIndex,
      activeType : newType
    });
  }

  setAdmin(mode){
    this.setState({
      adminMode : !this.state.adminMode,
      active : null,
      pending : null,
      concluded : null,
      stage : 'courses',
      activeId: 0,
      name: 'Dashboard',
      activeIndex: 0,
      activeType: 'dashboard'
    })
  }

  canvasLogin(){
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    if(!vars['code']){
      var target = 'https://cpeonline.ucdavis.edu/login/oauth2/auth?client_id=90380000000000006&response_type=code&redirect_uri=https://admindash.cpeonline.ucdavis.edu/';
      window.location = target;
    }else{
      this.setState({
        code : vars['code'],
        stage : 'login'
      })
    }
  }

  filterPending(){
    let pending = this.state.pending.filter((p) => {
      let found = false;
      found = this.state.active.find((a) => {
        return a.course_code === p.course_code;
      })

      if(found){
        return !found
      }

      found = this.state.concluded.find((c) => {
        return c.course_code === p.course_code;
      });

      return !found;
    })

    this.setState({
      pending
    })
  }

  //Popup Methods
  openPopupbox(content, config){
    PopupboxManager.open({ content, config });
  }

  updatePopupbox(content, config){
    PopupboxManager.update({ content, config });
  }


  //Lifecycle Methods
  componentDidMount(){
    this.canvasLogin();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.stage === 'login'){
      this.getKey();
    }else if(this.state.stage === 'quarters'){
      this.getQuarters();
    }else if(this.state.stage === 'accounts'){
      this.getAccounts();
    }else if(this.state.stage === 'courses'){
      this.setState({
        stage: 'retrieval'
      }, this.getCourses)
    }else if(this.state.stage === 'retrieval' && this.state.active !== null && this.state.pending !== null && this.state.concluded !== null){
      this.setState({
        stage : 'finished'
      })
      this.filterPending();
    }
  }

  render() {
    let message = ''
    if(this.state.stage === 'start'){
      message = <Loading message={'Redirecting to Canvas...'} />
    }if(this.state.stage === 'login'){
      message = <Loading message={'Getting Canvas Info...'} />
    }else if(this.state.stage === 'quarters'){
      message = <Loading message={'Getting Available Quarters...'} />
    }else if(this.state.stage === 'accounts'){
      message = <Loading message={'Checking Admin Rights...'} />
    }else if(this.state.stage === 'courses' || this.state.stage === 'retrieval'){
      message = <Loading message={'Getting Courses...'} />
    }else if(this.state.stage === 'finished'){
      message = <Dashboard active={this.state.active} pending={this.state.pending} concluded={this.state.concluded} type={this.state.activeType} code={this.state.name} report={this.state.report} user={this.state.db} layout={this.state.layout} />
    }

    const courses = {
      active: this.state.active,
      pending: this.state.pending,
      concluded: this.state.concluded
    }

    return (
      <div className="wrapper">
        <PopupboxContainer />
        <SideBar user={this.state.user} courses={courses} activeId={this.state.activeId} handleParentClick={this.handleClick}/>
        <div className="main-panel">
          <TopBar name={this.state.name} user={this.state.user} accounts={this.state.accounts} activeAccount={this.state.activeAccount} quarters={this.state.quarters} activeQuarter={this.state.activeQuarter} mode={this.state.adminMode} handleParentClick={this.setAdmin} updateAccount={this.updateAccount} updateQuarter={this.updateQuarter} />
          <div className="content">
            {message}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
