import React, { Component } from 'react';
import ReactLoading from 'react-loading';

class Loading extends Component {
  render(){
    return(
      <div className="content-message">
        <ReactLoading className="content-message" type="spinningBubbles" height={128} width={128} color={'darkgrey'}/>
        <h2>{this.props.message}</h2>
      </div>
    )
  }

}

export { Loading };
