import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class NoContacts extends Component {

  render(){
    return (
      <div className="content-message">
        <br />
        <FontAwesomeIcon size="5x" icon={['fas', "user-cog"]} />
        <br />
        <h5>There are no section contacts assigned to this course</h5>
      </div>
      )
  }

}

export default NoContacts;
