import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class NoInstructor extends Component {

  render(){
    return (
      <div className="content-message">
        <br />
        <FontAwesomeIcon size="5x" icon={['fas', "chalkboard-teacher"]} />
        <br />
        <h5>There are no instructors assigned to this course</h5>
      </div>
      )
  }

}

export default NoInstructor;
