import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopupboxManager } from 'react-popupbox';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';

class Announcements extends Component {
  constructor(props){
    super(props);

    this.state ={
      announcement : [],
      interval : false
    }

    this.getAnnouncement = this.getAnnouncement.bind(this);
    this.openPopupbox = this.openPopupbox.bind(this);
    this.updatePopupbox = this.updatePopupbox.bind(this);
    this.dismissAnnouncement = this.dismissAnnouncement.bind(this);
  }

  getAnnouncement(){
    var link = '/api/topbar/get_announcements.php';
    axios.get(link)
      .then(function (response) {
        this.setState({
          announcement : response.data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error.response.data);
        console.log(error.response.status);
        if(error.response.status === 401){
          setTimeout(this.getAnnouncement, 100);
        }
      }.bind(this));
  }

  dismissAnnouncement(e){
    console.log(e.currentTarget.dataset.id);
    console.log(e.currentTarget.dataset.index);
    var id = e.currentTarget.dataset.id
    var index = e.currentTarget.dataset.index
    var link = '/api/topbar/close_announcement.php';
    var data = {
      id
    };
    axios.post(link, data)
      .then(function (response) {
        let announcement = this.state.announcement;
        console.log(announcement);
        announcement.splice(index, 1)
        console.log(announcement);
        this.setState({
          announcement
        }, this.updatePopupbox)
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  openPopupbox(){
    const content = (
      <React.Fragment>
        <div className="popup-content">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th>Title</th>
                <th>Message</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {
              this.state.announcement.map((item, i) => {
                return(
                  <tr key={item.id}>
                    <td>{(item.type === "info") ? <FontAwesomeIcon icon="info-circle" color="blue" size="2x" /> : <FontAwesomeIcon icon="exclamation-circle" color="red" size="2x" />}</td>
                    <td>{item.title}</td>
                    <td>{ReactHtmlParser(item.content)}</td>
                    <td><FontAwesomeIcon icon="trash-alt" data-id={item.id} data-index={i} onClick={this.dismissAnnouncement}/></td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
          {
            this.state.announcement.length === 0 &&
              <strong className="popup-message">No Outstanding Announcements</strong>
          }
        </div>
      </React.Fragment>
    )
    const config ={
      titleBar: {
        enable: true,
        text: "Announcements"
      },
      fadeIn: true,
      fadeInSpeed: 500
    }
    PopupboxManager.open({ content, config });
  }

  updatePopupbox(){
    const content = (
      <React.Fragment>
        <div className="popup-content">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th>Title</th>
                <th>Message</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {
              this.state.announcement.map((item, i) => {
                return(
                  <tr key={item.id}>
                    <td>{item.type}</td>
                    <td>{item.title}</td>
                    <td>{ReactHtmlParser(item.content)}</td>
                    <td><FontAwesomeIcon icon="trash-alt" data-id={item.id} data-index={i} onClick={this.dismissAnnouncement}/></td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
          {
            this.state.announcement.length === 0 &&
              <strong className="popup-message">No Outstanding Announcements</strong>
          }
        </div>
      </React.Fragment>
    )
    const config ={
      titleBar: {
        enable: true,
        text: "Announcements"
      },
      fadeIn: true,
      fadeInSpeed: 500
    }
    PopupboxManager.update({ content, config });
  }

  componentDidMount(){
    this.getAnnouncement();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.announcement !== null && this.state.interval === false){
      this.setState({
        interval : true
      });
      setInterval(this.getAnnouncement, 300000);
    }
  }

  render(){
    let announcement = '';
    if(this.state.announcement !== null && this.state.announcement.length >= 1){
      announcement = <span className="notification hidden-sm hidden-xs">{this.state.announcement.length}</span>
    }
    return(
      <li className="nav-item">
        <span className="nav-link">
          <FontAwesomeIcon icon="bullhorn" onClick={this.openPopupbox}/>
          {announcement}
        </span>
      </li>
    )
  }

}

export { Announcements };
