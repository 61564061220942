import React from 'react';
import ReactLoading from 'react-loading';

function Loading(){
  return(
    <ReactLoading className="inline-loading" type="bubbles" height={32} width={32} color={'darkgrey'}/>
  )
}

export default Loading;
