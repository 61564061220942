import React, { Component } from 'react';
import Loading from './../loading.js';
import axios from 'axios';
import InstructorRow from './instructor_row.js';

class CourseRow extends Component{
  constructor(props){
    super(props);

    this.state = {
      instructors  : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getInstructors = this.getInstructors.bind(this);
  }

  getData(){
    this.getInstructors();
  }

  getInstructors(){
    var link = '/api/reports/dashboard/instructor/get_instructors.php';
    let state;
    if(this.props.course.workflow_state === undefined){
      state = false;
    }else{
      state = this.props.course.workflow_state;
    }
    var data = {
      code: this.props.course.course_code,
      id: this.props.course.id,
      state
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          instructors : response.data,
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentWillMount(){
    this.getData();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.course_code !== this.props.course.course_code){
      this.setState({
        instructors : null
      }, this.getData);
    }
  }

  render(){
    let info;
    if(this.state.instructors === null){
      info = <Loading />
    }else if(this.state.instructors.length === 0){
      info = <strong>No instructors assigned to this course</strong>
    }else{
      info = <table className="table table-striped table-center">
              <thead>
                <tr>
                  <th>Instructor</th>
                  <th className={((this.props.layout.w < 6) ? 'd-print-none' : 'd-none')}>Last Activity</th>
                  <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Last Login Request</th>
                  <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Last Canvas Action</th>
                  <th className={((this.props.layout.w < 6) ? 'd-print-none' : 'd-none')}>Message Activity</th>
                  <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Unread Messages</th>
                  <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Oldest Unread Message</th>
                  <th className={((this.props.layout.w < 8) ? 'd-print-none' : 'd-none')}>Forum Activity</th>
                  <th className={((this.props.layout.w > 7 && this.props.layout.w < 12) ? 'd-print-none' : 'd-none')}>Graded Forum Activity</th>
                  <th className={((this.props.layout.w < 12) ? 'd-none d-print-table-cell' : '')}>Unread Graded Posts</th>
                  <th className={((this.props.layout.w < 12) ? 'd-none d-print-table-cell' : '')}>Oldest Graded Posts</th>
                  <th className={((this.props.layout.w > 7 && this.props.layout.w < 12) ? 'd-print-none' : 'd-none')}>Ungraded Forum Activity</th>
                  <th className={((this.props.layout.w < 11) ? 'd-none d-print-table-cell' : '')}>Unread Ungraded Posts</th>
                  <th className={((this.props.layout.w < 11) ? 'd-none d-print-table-cell' : '')}>Oldest Ungraded Posts</th>
                </tr>
              </thead>
              <tbody>
                {this.state.instructors.map((item) => {
                  return(
                    <InstructorRow instructor={item} course={this.props.course} layout={this.props.layout} />
                  )
                })}
              </tbody>
            </table>
    }
    return(
      <React.Fragment>
        <h4>{((this.props.layout.w < 6) ? this.props.course.course_code : this.props.course.title || this.props.course.name)}</h4>
        {info}
      </React.Fragment>
    )
  }
}

export default CourseRow;
