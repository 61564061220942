import React, { Component } from 'react';
import Loading from '../loading.js';
import axios from 'axios';

class CourseReport extends Component {
  constructor(props){
    super(props)

    this.state = {
      user : null,
      role : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getUser = this.getUser.bind(this);
  }

  getUser(){
    var link = '/api/reports/pending/get_user.php';
    let  data = {
        id: this.props.user.user_id
      };
    let role = this.props.user.role_id;
    if(role === '10'){
      role = "Principal Instructor";
    }else if(role === '11'){
      role = "Secondary Instructor";
    }else if(role === '9'){
      role = "Program Admin";
    }else if(role === '20'){
      role = "Student Assistant";
    }else{
      role = "Unknown";
    }
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          user : response.data,
          role
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    if(this.props.user.lms === undefined){
      this.setState({
        user : this.props.user.user,
        role : this.props.user.role
      })
    }else{
      this.getUser();
    }
  }

  render(){
    if(this.state.user === null){
      return (
        <tr>
          <td>
            <Loading />
          </td>
          <td>
            <Loading />
          </td>
        </tr>
      )
    }else{
      return (
        <tr>
          <td>
            {this.state.user.name}
          </td>
          <td>
            {this.state.role}
          </td>
        </tr>
      )
    }
  }

}

export default CourseReport;
