import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../loading.js';
import moment from 'moment';
import axios from 'axios';

class RosterItem extends Component {
  constructor(props){
    super(props)

    this.state = {
      student : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getUser = this.getUser.bind(this);
  }

  getUser(){
    var link = '/api/reports/pending/get_user.php';
    let data;
    if(this.props.data.user_id === undefined){
      data = {
        id: this.props.data.user_id
      };
    }else{
      data = {
        id: this.props.data.user_id
      };
    }
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          student : response.data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    this.getUser();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }

  render(){
    let status;
    if(this.props.data.enrollment_state === undefined || this.props.data.enrollment_state === null){
      status = <FontAwesomeIcon icon="circle" color="#ED8B00"/>
    }else if(this.props.data.enrollment_state === 'active' ){
      if(moment().diff(moment(this.props.data.last_activity_at), 'days') < 3 ){
        status = <FontAwesomeIcon icon="circle" color="#87CB16"/>
      }else if(moment().diff(moment(this.props.data.last_activity_at), 'days') < 7){
        status = <FontAwesomeIcon icon="circle" color="#00B5E2"/>
      }else if(moment().diff(moment(this.props.data.last_activity_at), 'days') < 14){
        status = <FontAwesomeIcon icon="circle" color="#FFCD00"/>
      }else{
        status = <FontAwesomeIcon icon="circle" color="#FF4A55"/>
      }
    }else if(this.props.data.enrollment_state === 'inactive'){
      status = <FontAwesomeIcon icon="circle" color="#000000"/>
    }else if(this.props.data.enrollment_state === 'completed'){
      status = <FontAwesomeIcon icon="circle" color="#4286f4"/>
    }

    if(this.state.student === null){
      return <Loading />
    }else{
      return (
        <tr>
          <td className={((this.props.layout.w < 8) ? 'd-none d-print-table-cell' : '')}><img className="roster-profile-image" src={this.state.student.avatar_url} alt={this.state.student.name + " Profile Image"}/></td>
          <td>{this.state.student.name}</td>
          <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>{this.state.student.integration_id}</td>
          <td>{this.state.student.sis_user_id}</td>
          <td className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>{this.state.student.login_id}</td>
          <td>{status}</td>
        </tr>
      )
    }
  }

}

export default RosterItem;
