import React, { Component } from 'react';
import Loading from './../loading.js';
import axios from 'axios';
import Highlighter from "react-highlight-words";

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

class EmailRow extends Component{
  constructor(props){
    super(props);

    this.state = {
      section  : null,
      receipt  : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getData = this.getData.bind(this);
  }

  getData(){
    var link = '/api/reports/dashboard/email/get_email.php';
    let data = {
      course: this.props.course.course_code
    }
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          section: entities.decode(response.data.section_notes.replace(/<(?:.|\n)*?>/gm, '')),
          receipt : entities.decode(response.data.receipt_notes.replace(/<(?:.|\n)*?>/gm, ''))
        })
      }.bind(this))
      .catch(function (error) {
        if(error.message !== undefined){
          console.log(error);
        }
      });
  }
  componentDidMount(){
    this.getData();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.course_code !== this.props.course.course_code){
      this.setState({
        section  : null,
        receipt  : null,
      }, this.getData);
    }
  }

  render(){

    return(
      <tr>
        <td>{this.props.course.course_code}</td>
        <td>
          <table>
            <tr>
              <th>Section Notes:</th>
              <td>{(this.state.section !== null) ? <Highlighter textToHighlight={this.state.section} searchWords={[this.props.search]} /> : <Loading />}</td>
            </tr>
            <tr>
              <th>Receipt Notes:</th>
              <td>{(this.state.receipt !== null) ? <Highlighter textToHighlight={this.state.receipt} searchWords={[this.props.search]} /> : <Loading />}</td>
            </tr>
          </table>
        </td>
      </tr>
    )
  }
}

export default EmailRow;
