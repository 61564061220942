import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopupboxManager } from 'react-popupbox';
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

class Alerts extends Component {
  constructor(props){
    super(props);

    this.state ={
      alert : [],
      interval : false,
      link: "https://docs.google.com/document/d/1ZOrOkaBPDzGrtzwyfQqCULis5KkWlZIfzuyUtA3hfrw/edit?usp=sharing"
    }

    this.getAlert = this.getAlert.bind(this);
    this.openPopupbox = this.openPopupbox.bind(this);
    this.updatePopupbox = this.updatePopupbox.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
    this.dismissAll = this.dismissAll.bind(this);
  }

  getAlert(){
    var link = '/api/topbar/get_alerts.php';
    axios.get(link)
      .then(function (response) {
        this.setState({
          alert : response.data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error.response.data);
        console.log(error.response.status);
        if(error.response.status === 401){
          setTimeout(this.getAlert, 100);
        }
      }.bind(this));
  }

  dismissAlert(e){
    console.log(e.currentTarget.dataset.id);
    console.log(e.currentTarget.dataset.index);
    var id = e.currentTarget.dataset.id
    var index = e.currentTarget.dataset.index
    var link = '/api/topbar/close_alert.php';
    var data = {
      id
    };
    axios.post(link, data)
      .then(function (response) {
        let alert = this.state.alert;
        console.log(alert);
        alert.splice(index, 1)
        console.log(alert);
        this.setState({
          alert
        }, this.updatePopupbox)
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  dismissAll(){
    let alert = this.state.alert;
    alert.forEach(e => this.dismissAlert({currentTarget: { dataset: {id: e.user.id, index: 0}}}))
  }

  openPopupbox(){
    const content = (
      <React.Fragment>
        <div className="popup-content">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Course</th>
                <th>Message</th>
                <th>Date</th>
                <th><span className="special-link" onClick={this.dismissAll}>Dismiss All</span></th>
                <th><a className="float-right" href={this.state.link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon="question-circle" /></a></th>
              </tr>
            </thead>
            <tbody>
            {
              this.state.alert.map((item, i) => {
                return(
                  <tr key={item.alert.id}>
                    <td>{item.alert.course_code}</td>
                    <td>{item.alert.content}</td>
                    <td><Moment format="M/DD/YYYY">{moment(item.alert.date)}</Moment></td>
                    <td><FontAwesomeIcon icon="trash-alt" data-id={item.user.id} data-index={i} onClick={this.dismissAlert}/></td>
                    <td></td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
          {
            this.state.alert.length === 0 &&
              <strong className="popup-message">No Outstanding Alerts</strong>
          }
        </div>
      </React.Fragment>
    )
    const config ={
      titleBar: {
        enable: true,
        text: "Alerts"
      },
      fadeIn: true,
      fadeInSpeed: 500
    }
    PopupboxManager.open({ content, config });
  }

  updatePopupbox(){
    const content = (
      <React.Fragment>
        <div className="popup-content">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Course</th>
                <th>Message</th>
                <th>Date</th>
                <th><span className="special-link" onClick={this.dismissAll}>Dismiss All</span></th>
                <th><a className="float-right" href={this.state.link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon="question-circle" /></a></th>
              </tr>
            </thead>
            <tbody>
            {
              this.state.alert.map((item, i) => {
                return(
                  <tr key={item.alert.id}>
                    <td>{item.alert.course_code}</td>
                    <td>{item.alert.content}</td>
                    <td><Moment format="M/DD/YYYY">{moment(item.alert.date)}</Moment></td>
                    <td><FontAwesomeIcon icon="trash-alt" data-id={item.user.id} data-index={i} onClick={this.dismissAlert}/></td>
                    <td></td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
          {
            this.state.alert.length === 0 &&
              <strong className="popup-message">No Outstanding Alerts</strong>
          }
        </div>
      </React.Fragment>
    )
    const config ={
      titleBar: {
        enable: true,
        text: "Alerts"
      },
      fadeIn: true,
      fadeInSpeed: 500
    }
    PopupboxManager.update({ content, config });
  }

  componentDidMount(){
    this.getAlert();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.alert !== null && this.state.interval === false){
      this.setState({
        interval : true
      });
      setInterval(this.getAlert, 300000);
    }
  }

  render(){
    let alert = '';
    if(this.state.alert !== null && this.state.alert.length >= 1){
      alert = <span className="notification hidden-sm hidden-xs">{this.state.alert.length}</span>
    }
    return(
      <li className="nav-item">
        <span className="nav-link">
          <FontAwesomeIcon icon="exclamation-triangle" onClick={this.openPopupbox}/>
          {alert}
        </span>
      </li>
    )
  }

}

export { Alerts };
