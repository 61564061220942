import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './../loading.js';
import NoInstructor from './../special/no_instructor.js';
import InstructorRow from './instructor_row.js';

class CourseRow extends Component{
  componentDidMount(){
    let footer = (
      <div>
        <FontAwesomeIcon icon="circle" color="#87CB16"/> Good | <FontAwesomeIcon icon="circle" color="#FF9500"/> Concern | <FontAwesomeIcon icon="circle" color="#FF4A55"/> Bad
      </div>
    )
    this.props.setFooter(footer)
  }

  render(){
    if(this.props.status === null || this.props.status === undefined){
      return <Loading />
    }else if(this.props.instructors.length === 0 && this.props.status !== null && this.props.status !== undefined){
      return <NoInstructor />
    }else{
      return(
        <table className="table table-striped table-center">
          <thead>
            <tr>
              <th>Instructor</th>
              <th className={((this.props.layout.w < 6) ? 'd-print-none' : 'd-none')}>Last Activity</th>
              <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Last Login Request</th>
              <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Last Canvas Action</th>
              <th className={((this.props.layout.w < 6) ? 'd-print-none' : 'd-none')}>Message Activity</th>
              <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Unread Messages</th>
              <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Oldest Unread Message</th>
              <th className={((this.props.layout.w < 8) ? 'd-print-none' : 'd-none')}>Forum Activity</th>
              <th className={((this.props.layout.w > 7 && this.props.layout.w < 12) ? 'd-print-none' : 'd-none')}>Graded Forum Activity</th>
              <th className={((this.props.layout.w < 12) ? 'd-none d-print-table-cell' : '')}>Unread Graded Posts</th>
              <th className={((this.props.layout.w < 12) ? 'd-none d-print-table-cell' : '')}>Oldest Graded Posts</th>
              <th className={((this.props.layout.w > 7 && this.props.layout.w < 12) ? 'd-print-none' : 'd-none')}>Ungraded Forum Activity</th>
              <th className={((this.props.layout.w < 11) ? 'd-none d-print-table-cell' : '')}>Unread Ungraded Posts</th>
              <th className={((this.props.layout.w < 11) ? 'd-none d-print-table-cell' : '')}>Oldest Ungraded Posts</th>
            </tr>
          </thead>
          <tbody>
            {this.props.instructors.map((item) => {
              return(
                <InstructorRow instructor={item.user} course={this.props.course} layout={this.props.layout} />
              )
            })}
          </tbody>
        </table>
      )
    }
  }
}

export default CourseRow;
