import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';

class Badge extends Component{
  constructor(props){
    super(props)

    this.state = {
      time: null
    }

    this.getTime = this.getTime.bind(this);
  }

  getTime(){
    let link = "/api/reports/active/email_log.php";
    let data = {
      id: this.props.user.id,
      course: this.props.course.id,
    }
    axios.post(link, data)
      .then(response => {
        if(response.data.length !== 0){
          this.setState({
            time: moment().diff(moment(response.data.pop().sent), 'days')
          })
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  componentDidMount(){
    this.getTime();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.sending !== this.props.sending){
      this.getTime();
    }
  }

  render(){
    if(this.state.time === null){
      return <></>
    }else{
      return <span className="badge badge-pill badge-success time-note">{(this.state.time === 0) ? "Today" : (this.state.time > 7) ? "7+ days" : (this.state.time === 1) ? "Yesterday" : this.state.time + " days"}</span>
    }
  }
}

export default Badge;
