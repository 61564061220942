import React, { Component } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import Report from './report.js';
import Dropdown from './dropdown.js';
import axios from 'axios';

const ResponsiveGridLayout = WidthProvider(Responsive);

class Dashboard extends Component {
  constructor(props){
    super(props);

    this.state = {
      layout : props.layout,
      currentLayout : props.layout,
      reports : props.report,
      visible_reports : [],
      invisible_reports : [],
      report_data : {
        instructors : [],
        enrollments : [],
        pas : [],
        instructor_status : null,
        enrollment_status : null,
        pa_status : null
      },
      cancelToken : axios.CancelToken.source()
    }

    this.findLayout = this.findLayout.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.updateReports = this.updateReports.bind(this);
    this.toggleReport = this.toggleReport.bind(this);
    this.findTarget = this.findTarget.bind(this);

    //General Data Functions
    this.getData = this.getData.bind(this);
    this.getActiveData = this.getActiveData.bind(this);
    this.getPendingData = this.getPendingData.bind(this);
    this.getConcludedData = this.getConcludedData.bind(this);

    //Data Functions
    //Active
    this.getActiveInstructors = this.getActiveInstructors.bind(this);
    this.getActivePAs = this.getActivePAs.bind(this);
    this.getActiveEnrollments = this.getActiveEnrollments.bind(this);

    //Pending
    this.getPendingInstructors = this.getPendingInstructors.bind(this);
    this.getPendingPAs = this.getPendingPAs.bind(this);
    this.getPendingEnrollments = this.getPendingEnrollments.bind(this);

    //Concluded
    this.getConcludedInstructors = this.getConcludedInstructors.bind(this);
    this.getConcludedPAs = this.getConcludedPAs.bind(this);
    this.getConcludedEnrollments = this.getConcludedEnrollments.bind(this);
  }

  findLayout(index){
    for(let i = 0; i < this.state.layout.length; i++){
      if(this.state.layout[i].i === index){
        return this.state.layout[i];
      }
    }
  }

  onLayoutChange(currentLayout){
    let layout = this.state.layout;
    for(let i = 0; i < layout.length; i++){
      for(let j = 0; j < currentLayout.length; j++){
        if(layout[i].i === currentLayout[j].i){
          layout[i] = currentLayout[j];
          break;
        }
      }
    }
    this.setState({
      layout,
      currentLayout
    }, this.updateLayout());
  }

  updateLayout(layout){
    var link = '/api/global/update_layout.php';
    var data = {
      user: this.props.user,
      layout: this.state.layout
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
          //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  updateReports(){
    let visible_reports = this.state.reports.filter(function(i){
      return i.visible === '1' && i.area === this.props.type;
    }.bind(this))
    let invisible_reports = this.state.reports.filter(function(i){
      return i.visible === '0' && i.area === this.props.type;
    }.bind(this))
    this.setState({
      visible_reports,
      invisible_reports,
      currentLayout : this.state.layout
    })
  }

  updateReportDB(report){
    var link = '/api/global/update_report.php';
    var data = {
      user: this.props.user,
      report
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
          //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  findTarget(){
    let target;
    if(this.props.type === 'dashboard'){
      target = this.props.active.concat(this.props.pending, this.props.concluded);
    }else if(this.props.type === 'active'){
      for(let i = 0; i < this.props.active.length; i++){
        if(this.props.active[i].course_code === this.props.code){
          target = this.props.active[i];
          break;
        }
      }
    }else if(this.props.type === 'pending'){
      for(let i = 0; i < this.props.pending.length; i++){
        if(this.props.pending[i].course_code === this.props.code){
          target = this.props.pending[i];
          break;
        }
      }
    }else if(this.props.type === 'concluded'){
      for(let i = 0; i < this.props.concluded.length; i++){
        if(this.props.concluded[i].course_code === this.props.code){
          target = this.props.concluded[i];
          break;
        }
      }
    }
    this.setState({
      target
    }, () => this.getData())
  }

  getData(){
    if(this.props.type === 'active'){
      this.getActiveData();
    }else if(this.props.type === 'pending'){
      this.getPendingData();
    }else if(this.props.type === 'concluded'){
      this.getConcludedData();
    }
  }

  //Active Course Specific Data
  getActiveData(){
    this.getActiveInstructors();
    this.getActivePAs();
    this.getActiveEnrollments();

    this.getPendingInstructors();
    this.getPendingPAs();
    this.getPendingEnrollments();
  }

  //Pending Course Specific Data
  getPendingData(){
    this.getPendingInstructors();
    this.getPendingPAs();
    this.getPendingEnrollments();
  }

  //Concluded Course Specific Data
  getConcludedData(){
    this.getConcludedInstructors();
    this.getConcludedPAs();
    this.getConcludedEnrollments();
  }

  //Data Retrieval
  //Active Courses
  getActiveInstructors(){
    var link = '/api/reports/active/get_instructors.php';
    var data = {
      id: this.state.target.id
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.instructors = report_data.instructors.concat(response.data);
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getActivePAs(){
    var link = '/api/reports/active/get_pas.php';
    var data = {
      id: this.state.target.id
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.pas = report_data.pas.concat(response.data);
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getActiveEnrollments(){
    var link = '/api/reports/active/get_enrollments.php';
    var data = {
      id: this.state.target.id
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.enrollments = report_data.enrollments.concat(response.data);
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  //Pending Courses
  getPendingInstructors(){
    var link = '/api/reports/pending/get_instructors.php';
    var data = {
      code: this.state.target.course_code
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.instructors = report_data.instructors.concat(response.data)
        report_data.instructor_status = 'done';
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getPendingPAs(){
    var link = '/api/reports/pending/get_pas.php';
    var data = {
      code: this.state.target.course_code
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.pas = report_data.pas.concat(response.data);
        report_data.pa_status = 'done';
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getPendingEnrollments(){
    var link = '/api/reports/pending/get_enrollments.php';
    var data = {
      code: this.state.target.course_code
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.enrollments = report_data.enrollments.concat(response.data);
        report_data.enrollment_status = 'done';
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  //Concluded Courses
  getConcludedInstructors(){
    var link = '/api/reports/concluded/get_instructors.php';
    var data = {
      id: this.state.target.id
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.instructors = report_data.instructors.concat(response.data);
        report_data.instructor_status = 'done';
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getConcludedPAs(){
    var link = '/api/reports/concluded/get_pas.php';
    var data = {
      id: this.state.target.id
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.pas = report_data.pas.concat(response.data);
        report_data.pa_status = 'done';
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getConcludedEnrollments(){
    var link = '/api/reports/concluded/get_enrollments.php';
    var data = {
      id: this.state.target.id
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let report_data = this.state.report_data;
        report_data.enrollments = report_data.enrollments.concat(response.data);
        report_data.enrollment_status = 'done';
        this.setState({
          report_data
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  toggleReport(id){
    let newReports = this.state.reports;
    for(let i = 0; i < newReports.length; i++){
      if(newReports[i].id === id){
        if(newReports[i].visible === '1'){
          newReports[i].visible = '0';
        }else{
          newReports[i].visible = '1';
        }
        this.updateReportDB(newReports[i]);
        break;
      }
    }
    this.setState({
      reports : newReports
    }, this.updateReports())
  }

  componentWillMount(){
    this.updateReports();
    this.findTarget();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.type !== prevProps.type){
      this.setState({
        report_data : {
          instructors : [],
          enrollments : [],
          pas : []
        }
      }, () => this.updateReports())
    }
    if(this.props.code !== prevProps.code){
      this.setState({
        report_data : {
          instructors : [],
          enrollments : [],
          pas : []
        }
      }, () => this.findTarget())
    }
  }

  render(){
    return(
      <React.Fragment>
        <ResponsiveGridLayout className="layout" rowHeight={100} draggableCancel=".no-drag" draggableHandle=".drag"
          breakpoints={{ lg: 1600, md: 1200, sm: 800, xs: 400 }}
          cols={{ lg:16, md: 12, sm: 8, xs: 4 }}
          layouts={{ lg: this.state.currentLayout }}
          onLayoutChange={this.onLayoutChange}>
          {this.state.visible_reports.map((i) => {
            return(
              <div key={i.id}>
                <Report user={this.props.user} report={i} data={this.state.report_data} layout={this.findLayout(i.id)} target={this.state.target} type={this.props.type} toggleReport={this.toggleReport} />
              </div>
            )
          })}
        </ResponsiveGridLayout>
        <Dropdown reports={this.state.invisible_reports} onClick={this.toggleReport}/>
      </React.Fragment>
    )
  }

}

export { Dashboard };
