import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../loading.js';
import NoAssignments from '../special/no_assignments.js';
import axios from 'axios';
import AssignmentItem from './assignment_item.js';

class CourseReport extends Component {
  constructor(props){
    super(props);

    this.state = {
      groups: [],
      loaded: false,
      cancelToken : axios.CancelToken.source()
    }
  }

  getGroups(){
    var link = '/api/reports/active/get_assignment_groups.php';
    var data = {
      id: this.props.course.id
    };

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        for(let i = 0; i < response.data.length; i++){
          response.data[i].assignments = response.data[i].assignments.sort(function(a, b) {
            return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
          })
        }
        this.setState({
          groups: response.data,
          loaded: true
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    let footer = (
      <div>
        <FontAwesomeIcon icon="circle" color="#87CB16"/> Published | <FontAwesomeIcon icon="circle" color="#FF4A55"/> Unpublished
      </div>
    )
    this.props.setFooter(footer)
    this.getGroups();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.course_code !== this.props.course.course_code){
      this.setState({
        groups : [],
        loaded: false
      }, () => this.getGroups());
    }
  }

  render(){
    if(this.state.loaded === false){
      return <Loading />
    }else if(this.state.groups.length === 0 || (this.state.groups.length === 1 && this.state.groups[0].assignments.length === 0)){
      return <NoAssignments />
    }else{
      return (
        <React.Fragment>
          {
            this.state.groups.map((item) => {
              return(
                <div>
                  <h4>{item.name}</h4>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Due</th>
                        <th className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>Graded</th>
                        <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Ungraded</th>
                        <th className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>Unsubmitted</th>
                        <th>Published</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        item.assignments.map((a) => {
                          return <AssignmentItem course={this.props.course.id} assignment={a} layout={this.props.layout} />
                        })
                      }
                    </tbody>
                  </table>
                </div>
              )
            })
          }
        </React.Fragment>
    )}
  }

}

export default CourseReport;
