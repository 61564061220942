import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './../loading.js';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';

class InstructorActivity extends Component{
  constructor(props){
    super(props);

    this.state = {
      request : null,
      action  : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getData = this.getData.bind(this);
    this.getLogin = this.getLogin.bind(this);
    this.getActivity = this.getActivity.bind(this);
  }

  getData(){
    this.getLogin();
    this.getActivity();
  }

  getLogin(){
    var link = '/api/reports/dashboard/instructor/get_login.php';
    var data = {
      id: this.props.instructor.id,
      name: this.props.instructor.name
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          request : response.data,
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  getActivity(){
    var link = '/api/reports/dashboard/instructor/get_activity.php';
    var data = {
      id: this.props.instructor.id,
      name: this.props.instructor.name
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          action : response.data,
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    this.getData();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }

  render(){
    let now = new moment();
    let log_duration = now.diff(moment(this.state.request), 'days');
    let log_status;
    if(this.state.request === null){
      log_status = <Loading />
    }else if(this.state.request === false){
      log_status = <React.Fragment>No Log In <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>
    }else if(log_duration >= 7){
      log_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.request)}</Moment> <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>
    }else if(log_duration >= 3){
      log_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.request)}</Moment> <FontAwesomeIcon icon="circle" color="#FF9500" /></React.Fragment>
    }else{
      log_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.request)}</Moment> <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>
    }

    let activity_duration = now.diff(moment(this.state.action), 'days');
    let overall_status;
    let activity_status;
    if(this.state.action === null){
      overall_status = <Loading />
      activity_status = <Loading />
    }else if(this.state.action === false){
      overall_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />
      activity_status = <React.Fragment>No Activity <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>
    }else if(activity_duration >= 7){
      overall_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />
      activity_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.action)}</Moment> <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>
    }else if(activity_duration >= 3){
      overall_status = <FontAwesomeIcon icon="circle" color="#FF9500" />
      activity_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.action)}</Moment> <FontAwesomeIcon icon="circle" color="#FF9500" /></React.Fragment>
    }else{
      overall_status = <FontAwesomeIcon icon="circle" color="#87CB16" />
      activity_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.action)}</Moment> <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>
    }

    return(
      <React.Fragment>
        <td className={((this.props.layout.w < 6) ? 'd-print-none' : 'd-none')}>{overall_status}</td>
        <td className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>{log_status}</td>
        <td className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>{activity_status}</td>
      </React.Fragment>
    )
  }
}

export default InstructorActivity;
