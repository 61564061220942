import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel } from 'react-responsive-carousel';
import StudentItem from './student_item.js';

class StudentReport extends Component {

  componentDidMount(){
    let footer = (
      <div>
        <FontAwesomeIcon icon="circle" color="#87CB16"/> Active | <FontAwesomeIcon icon="circle" color="#FF9500"/> Pending | <FontAwesomeIcon icon="circle" color="#FF4A55"/> Concluded
      </div>
    )
    this.props.setFooter(footer)
  }

  render(){
    let enrollments = this.props.enrollments.filter((item) => {
      return item.enrollment_state === 'active';
    })
    return (
      <div className="carousel-container">
        <Carousel showThumbs={false} infiniteLoop={true} showIndicators={false} >
          {enrollments.map((item) => {
            return <StudentItem enrollment={item} layout={this.props.layout} course={this.props.course} />
          })}
        </Carousel>
      </div>
    )
  }

}

export default StudentReport;
