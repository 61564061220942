import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import axios from 'axios';
import Badge from './badge';
import Loading from '../../global/inline_loading';

class EmailBlaster extends Component{
  constructor(props){
    super(props)

    this.state = {
      students: props.enrollments.filter(e => e.enrollment_state === "active" || e.action === "0"),
      subject: '',
      salutation: '',
      content: '',
      preview: false,
      all: false,
      good: false,
      three: false,
      seven: false,
      none: false,
      copy: false,
      pending: false,
      sending: false
    }

    this.updateContent = this.updateContent.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.togglePreview = this.togglePreview.bind(this);
    this.toggleCopy = this.toggleCopy.bind(this);
    this.toggleCategory = this.toggleCategory.bind(this);
    this.send = this.send.bind(this);
    this.prepSend = this.prepSend.bind(this);
  }

  selectOne(i){
    let students = this.state.students;
    if(students[i].selected){
      students[i].selected = false;
    }else{
      students[i].selected = true;
    }
    this.setState({
      students
    })
  }

  updateContent(content, editor){
    this.setState({
      content
    })
  }

  updateValue(e){
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  togglePreview(){
    this.setState({
      preview : !this.state.preview
    })
  }

  toggleCopy(){
    this.setState({
      copy : !this.state.copy
    })
  }

  toggleCategory(value, name){
    let students = this.state.students;

    switch(name){
      case 'good':
        students.forEach(e => (moment().diff(moment(e.last_activity_at), 'days') < 3) ? e.selected = value : e);
        break;
      case 'three':
        students.forEach(e => (moment().diff(moment(e.last_activity_at), 'days') >= 3 && moment().diff(moment(e.last_activity_at), 'days') < 7) ? e.selected = value : e);
        break;
      case 'seven':
        students.forEach(e => (e.last_activity_at !== null && moment().diff(moment(e.last_activity_at), 'days') >= 7) ? e.selected = value : e);
        break;
      case 'never':
        students.forEach(e => (e.last_activity_at === null) ? e.selected = value : e);
        break;
      case 'pending':
        students.forEach(e => (e.action === '0') ? e.selected = value : e);
        break;
      case 'all':
        students.forEach(e => e.selected = value);
        break;
      default:
        console.error("Something went wrong!");
        break;
    }

    this.setState({
      students,
      [name] : value
    })
  }

  prepSend(){
    this.setState({
      sending: true
    }, this.send);
  }

  send(){
    let link = "/api/reports/active/send_email.php";
    let data = {
      id: this.props.user.canvas_id,
      course: this.props.course.id,
      rec : this.state.students.filter(e => e.selected === true),
      subject : this.state.subject,
      salutation : this.state.salutation,
      message : this.state.content,
      user : this.props.user,
      copy: this.state.copy
    }
    axios.post(link, data)
      .then(response => {
        console.log(response.data);
        this.setState({
          subject: '',
          content: '',
          salutation: '',
          sending: false
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  componentDidMount(){
    let footer = (
      <div>
        <span className ="badge badge-primary to-badge">Recipent Of Email</span>
        <span className="badge badge-light to-badge">Accessed Course Within 3 Days</span>
        <span className="badge badge-info to-badge">Has Not Accessed Course Within 3 Days</span>
        <span className="badge badge-warning to-badge">Has Not Accessed Course Within 7 Days</span>
        <span className="badge badge-danger to-badge">Never Accessed Course</span>
        <span className="badge badge-dark to-badge">Pending Access</span>
        <span className="position-relative"><span className="badge badge-light to-badge">Student Name</span><span className="badge badge-pill badge-success time-note">Last Email Blast</span></span>
      </div>
    )
    this.props.setFooter(footer)
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course_code !== this.props.course_code){
      this.setState({
        students: this.props.enrollments.filter(e => e.enrollment_state === "active" || e.action === "0"),
        subject: '',
        salutation: '',
        content: '',
        preview: false,
        all: false,
        good: false,
        three: false,
        seven: false,
        none: false
      })
    }else if(prevProps.enrollments.length !== this.props.enrollments.length){
      this.setState({
        students: this.props.enrollments.filter(e => e.enrollment_state === "active" || e.action === "0"),
        subject: '',
        salutation: '',
        content: '',
        preview: false,
        all: false,
        good: false,
        three: false,
        seven: false,
        none: false
      })
    }
  }

  render(){
    if(this.state.preview){
      return(
        <div>
          <button className="btn btn-primary" onClick={this.togglePreview}>Back</button>
          <br /><br />
          <p><strong>Subject:</strong> {this.state.subject}</p>
          <p>{this.state.salutation} {this.state.students[0].user.name},</p>
          <p>{ReactHtmlParser(this.state.content)}</p>
        </div>
      )
    }else{
      return(
        <div>
          <div>
            <span className ="badge badge-primary to-badge" onClick={() => this.toggleCategory(!this.state.all, 'all')}>{(!this.state.all) ? "Select All" : "Deselect All"}</span>
            <span className="badge badge-light to-badge" onClick={() => this.toggleCategory(!this.state.good, 'good')}>{(!this.state.good) ? "Select All" : "Deselect All"}</span>
            <span className="badge badge-info to-badge" onClick={() => this.toggleCategory(!this.state.three, 'three')}>{(!this.state.three) ? "Select All" : "Deselect All"}</span>
            <span className="badge badge-warning to-badge" onClick={() => this.toggleCategory(!this.state.seven, 'seven')}>{(!this.state.seven) ? "Select All" : "Deselect All"}</span>
            <span className="badge badge-danger to-badge" onClick={() => this.toggleCategory(!this.state.never, 'never')}>{(!this.state.never) ? "Select All" : "Deselect All"}</span>
            <span className="badge badge-dark to-badge" onClick={() => this.toggleCategory(!this.state.pending, 'pending')}>{(!this.state.pending) ? "Select All" : "Deselect All"}</span>
          </div>
          <label htmlFor="to">To:</label>
          <div id="to">
            {this.state.students.map((e, i) => <span className="position-relative"><span onClick={() => this.selectOne(i)} className={(e.selected) ? "badge badge-primary to-badge" : (e.action === "0") ? "badge badge-dark to-badge" : (moment().diff(moment(e.last_activity_at), 'days') < 3 ) ? "badge badge-light to-badge" : (moment().diff(moment(e.last_activity_at), 'days') < 7 ) ? "badge badge-info to-badge" : (e.last_activity_at !== null) ? "badge badge-warning to-badge" : "badge badge-danger to-badge"}>{e.user.name}</span><Badge user={e.user} course={this.props.course} sending={this.state.sending}/></span>)}
          </div>
          <br />
          <label htmlFor="subject">Subject:</label>
          <input id="subject" name="subject" type="text" className="form-control" placeholder="Subject" disabled={this.state.sending} value={this.state.subject} onChange={this.updateValue} />
          <br />
          <label htmlFor="salutation">Salutation:</label>
          <input id="salutation" name="salutation" type="text" className="form-control" placeholder="Saluation" disabled={this.state.sending} value={this.state.salutation} onChange={this.updateValue} />
          <br />
          <div className="custom-checkbox">
            <label htmlFor="copy">Recive Copy?</label>
            <input className="custom-control-input" type="checkbox" id="copy" disabled={this.state.sending} checked={this.state.copy} onChange={this.toggleCopy}/>
            <label className="checkmark" htmlFor="copy" />
          </div>
          <br />
          <Editor apiKey="amxv7rpec03f2u23c9d9mzz3n0u4d6jb5z918e7hjq72bc10" disabled={this.state.sending} value={this.state.content} onEditorChange={this.updateContent} />
          <br />
          <button className="btn btn-primary float-right" disabled={this.state.sending} onClick={this.prepSend}>Send</button>
          <button className="btn float-right mx-2" disabled={this.state.sending} onClick={this.togglePreview}>{(this.state.sending) ? <Loading /> : "Preview"}</button>
        </div>
      )
    }
  }
}

export default EmailBlaster;
