import React, { Component } from 'react';

class CourseReport extends Component {
  render(){
    if(this.props.type === 'pending'){
      return (
        <div>
          <h3 className={((this.props.layout.w < 7) ? 'd-none d-print-block' : '')}>{this.props.course.title}</h3>
          <h3 className={((this.props.layout.w < 7) ? 'd-print-none' : 'd-none')}>{this.props.course.shortname}</h3>
          <h4 className={((this.props.layout.w < 7) ? 'd-none d-print-block' : '')}><em>{this.props.course.shortname}</em></h4>
          <strong>Total Enrollments: {this.props.enrollments}</strong>
        </div>
      )
    }else{
      return (
        <div>
          <h3 className={((this.props.layout.w < 7) ? 'd-none d-print-block' : '')}>{this.props.course.name}</h3>
          <h3 className={((this.props.layout.w < 7) ? 'd-print-none' : 'd-none')}>{this.props.course.course_code}</h3>
          <h4 className={((this.props.layout.w < 7) ? 'd-none d-print-block' : '')}><em>{this.props.course.course_code}</em></h4>
          <strong>Total Enrollments: {this.props.enrollments}</strong>
        </div>
      )
    }
  }

}

export default CourseReport;
