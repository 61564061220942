import React, { Component } from 'react';
import Loading from './../loading.js';
import Moment from 'react-moment';
import moment from 'moment';

class StudentProlife extends Component {
  render(){
    let image = <Loading />;
    if(this.props.student !== null){
      image = <img alt={this.props.enrollment.user.name} className="student-profile-image" src={this.props.student.avatar_url} />
    }
    return (
      <div  className={((this.props.layout.w < 8) ? 'student-report-full' : ((this.props.layout.w < 11) ? 'student-report-half' : 'student-report-third'))}>
        <div className={((this.props.layout.h < 5) ? 'd-none d-print' : '')}>
          {image}
        </div>
        <div>
          <h2>{this.props.enrollment.user.name}</h2>
          <p>Last Access: <Moment format="M/DD/YYYY">{moment(this.props.enrollment.last_activity_at)}</Moment></p>
          <p>Total Activity: {moment.duration(this.props.enrollment.total_activity_time, 'seconds').humanize()}</p>
          <p>Current Grade: {this.props.enrollment.grades.current_grade}</p>
          <p>Final Grade: {this.props.enrollment.grades.final_grade}</p>
        </div>
      </div>
    )
  }
}

export default StudentProlife;
