import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Mail } from './../topbar/mail.js';
//import { Tickets } from './../topbar/tickets.js';
import { Alerts } from './../topbar/alerts.js';
import { Announcements } from './../topbar/announce.js';

class TopBar extends Component{
  constructor(props){
    super(props);

    this.handleAdminClick = this.handleAdminClick.bind(this);
    this.updateQuarter = this.updateQuarter.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
  }

  handleAdminClick(e) {
    this.props.handleParentClick();
  }

  updateQuarter(e){
    this.props.updateQuarter(e.target.value, e.target[e.target.selectedIndex].getAttribute('data-code'));
  }

  updateAccount(e){
    this.props.updateAccount(e.target.value);
  }

  render(){
    var admin = '';
    if(this.props.accounts === null){
      admin =
        <li className="nav-item">
          <span className="nav-link">
            Checking Admin Rights...
          </span>
        </li>;
    }else if(this.props.accounts.length !== 0){
      if(this.props.mode){
        admin =
          <li className="nav-item">
            <span className="nav-link" onClick={this.handleAdminClick}>
              <FontAwesomeIcon icon='school' pull="left"/>
              Courses
            </span>
          </li>;
      }else{
        admin =
          <li className="nav-item">
            <span className="nav-link" onClick={this.handleAdminClick}>
              <FontAwesomeIcon icon='key' pull="left"/>
              Admin
            </span>
          </li>;
      }
    }

    var quarters = '';
    if(this.props.quarters === null){
      quarters =
        <li className="nav-item">
          <span className="nav-link">
            Getting Quarters...
          </span>
        </li>;
    }else{
      quarters =
        <li className="nav-item">
          <span className="nav-link">
            <form>
              <select className="custom-select" name='group' id='group' value={this.props.activeQuarter} onChange={this.updateQuarter}>
                  {this.props.quarters.map(function(quarter){
                    return (
                      <option value={quarter.id} key={quarter.id} data-code={quarter.name.substring(0,3)}>
                        {quarter.name}
                      </option>
                    );
                  })}
              </select>
            </form>
          </span>
        </li>;
    }

    var accounts = '';
    if(this.props.accounts === -1){
      accounts = <div />;
    }else if(this.props.accounts === null && this.props.mode){
      accounts =
        <li className="nav-item">
          <span className="nav-link">
            Getting Accounts...
          </span>
        </li>;
    }else if(this.props.mode){
      accounts =
        <li className="nav-item">
          <span className="nav-link">
            <form>
              <select className="custom-select" name='group' id='group' value={this.props.activeAccount} onChange={this.updateAccount}>
                  {this.props.accounts.map(function(account){
                    return (
                      <option value={account.id} key={account.id}>
                        {account.name}
                      </option>
                    );
                  })}
              </select>
            </form>
          </span>
        </li>;
    }

    return(
      <nav className="navbar navbar-expand-lg" color-on-scroll="500">
        <div className="container-fluid">
          <span className="navbar-brand"> {this.props.name} </span>
          <div className="collapse navbar-collapse justify-content-start">
            <ul className="navbar-nav">
              <Mail user={this.props.user}/>
              {/*<Tickets />*/}
              <Alerts />
              <Announcements />
            </ul>
          </div>
          <div className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav ml-auto">
                {quarters}
                {accounts}
                {admin}
              </ul>
          </div>
        </div>
      </nav>
    )
  }
}

export { TopBar }
