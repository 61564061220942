import React, { Component } from 'react';
import axios from 'axios';

class AccountSelect extends Component{
  constructor(props){
    super(props);

    this.state = {
      name: "Retrieving Name..."
    }

    this.getName = this.getName.bind(this);
  }

  getName(){
    let link = "/api/reports/dashboard/account/get_account.php";
    let data = {
      id: this.props.id
    }
    axios.post(link, data)
      .then(response => {
        this.setState({
          name: response.data.name
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  componentDidMount(){
    this.getName();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.id !== this.props.id){
      this.setState({
        name: "Retrieving Name..."
      }, this.getName)
    }
  }

  render(){
    return(
      <option value={this.props.id}>{this.state.name}</option>
    )
  }

}

export default AccountSelect;
