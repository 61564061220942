import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CourseData from './course_data.js';
import CourseDates from './course_dates.js';
import CourseGrades from './course_grades.js';
import CourseMembers from './course_members.js';
import CourseSurveys from './course_surveys.js';

class CourseReport extends Component {

  componentDidMount(){
    let footer = (
      <div>
        <FontAwesomeIcon icon="circle" color="#87CB16"/> Active | <FontAwesomeIcon icon="circle" color="#FF9500"/> Pending | <FontAwesomeIcon icon="circle" color="#FF4A55"/> Concluded
      </div>
    )
    this.props.setFooter(footer)
  }

  render(){
    return (
      <div>
        <CourseData course={this.props.courses} enrollments={this.props.data.enrollments.length} type={this.props.type} layout={this.props.layout} />
        <CourseDates course={this.props.courses} type={this.props.type} layout={this.props.layout} />
        <CourseGrades course={this.props.courses} type={this.props.type} layout={this.props.layout} />
        <CourseMembers course={this.props.courses} instructors={this.props.data.instructors} instructor_status={this.props.data.instructor_status} pas={this.props.data.pas} pa_status={this.props.data.pa_status} type={this.props.type} layout={this.props.layout}/>
        <CourseSurveys course={this.props.courses} type={this.props.type} layout={this.props.layout} />
      </div>
    )
  }

}

export default CourseReport;
