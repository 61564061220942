import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './../loading.js';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';

class CourseRow extends Component{
  constructor(props){
    super(props);

    this.state = {
      name  : <Loading />,
      state  : <Loading />,
      start : <Loading />,
      end : <Loading />,
      instructors : <Loading />,
      contacts : <Loading />,
      grade : <Loading />,
      cancelToken : axios.CancelToken.source(),
      submitted : false
    }

    this.getData = this.getData.bind(this);
    this.setData = this.setData.bind(this);
    this.getInstructors = this.getInstructors.bind(this);
    this.getContacts = this.getContacts.bind(this);
    this.getGrade = this.getGrade.bind(this);
    this.getGradeSubmitted = this.getGradeSubmitted.bind(this);
  }

  getData(){
    this.setData();
    this.getInstructors();
    this.getContacts();
    this.getGrade();
  }

  setData(){
    let state = '';
    let start = '';
    let end = '';
    let name = '';
    if(this.props.course.workflow_state === undefined){
      state = <FontAwesomeIcon icon="circle" color="#FF9500"/>;
      start = <Moment format="M/DD/YYYY">{moment(this.props.course.start_at)}</Moment>;
      end = <Moment format="M/DD/YYYY">{moment(this.props.course.end_at)}</Moment>;
      name = this.props.course.name;
    }else{
      if(this.props.course.workflow_state === "available" || this.props.course.workflow_state === "unpublished"){
        if(moment().isAfter(moment(this.props.course.end_at))){
          this.getGradeSubmitted();
        }else{
          state = <FontAwesomeIcon icon="circle" color="#87CB16"/>;
        }
      }else{
        state = <FontAwesomeIcon icon="circle" color="#4286f4"/>;
      }
      start = <Moment format="M/DD/YYYY">{this.props.course.start_at}</Moment>
      end = <Moment format="M/DD/YYYY">{this.props.course.end_at}</Moment>
      name = this.props.course.name
    }
    this.setState({
      state,
      start,
      end,
      name
    })
  }

  getInstructors(){
    var link = '/api/reports/dashboard/course/get_instructors.php';
    let state;
    if(this.props.course.workflow_state === undefined){
      state = false;
    }else{
      state = this.props.course.workflow_state;
    }
    var data = {
      code: this.props.course.course_code,
      id: this.props.course.id,
      state
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          instructors : response.data,
        })
      }.bind(this))
      .catch(function (error) {
        if(error.message !== undefined){
          console.log(error);
        }
      });
  }

  componentWillMount(){
    this.getData();
  }

  getContacts(){
    var link = '/api/reports/dashboard/course/get_contacts.php';
    let state;
    if(this.props.course.workflow_state === undefined){
      state = false;
    }else{
      state = this.props.course.workflow_state;
    }
    var data = {
      code: this.props.course.course_code,
      id: this.props.course.id,
      state
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          contacts : response.data,
        })
      }.bind(this))
      .catch(function (error) {
        if(error.message !== undefined){
          console.log(error);
        }
      });
  }

  getGrade(){
    var link = '/api/reports/dashboard/course/get_grade_scheme.php';
    let data;
    if(this.props.course.workflow_state === undefined){
      data = {
        id: this.props.course.validgrades,
        state: false
      };
    }else{
      data = {
        id: this.props.course.grading_standard_id,
        state: this.props.course.workflow_state
      };
    }

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        this.setState({
          grade : response.data,
        })
      }.bind(this))
      .catch(function (error) {
        if(error.message !== undefined){
          console.log(error);
        }
      });
  }

  getGradeSubmitted(){
    var link = '/api/reports/dashboard/course/get_grade_submitted.php';
    var data = {
      code: this.props.course.course_code
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let state;
        if(response.data.length > 0){
          state = <FontAwesomeIcon icon="circle" color="#00B5E2"/>;
        }else{
          state = <FontAwesomeIcon icon="circle" color="#FF4A55"/>;
        }
        this.setState({
          state
        })
      }.bind(this))
      .catch(function (error) {
        if(error.message !== undefined){
          console.log(error);
        }
      });

  }

  componentDidMount(){
    this.getData();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.course.course_code !== this.props.course.course_code){
      this.setState({
        name  : <Loading />,
        state  : <Loading />,
        start : <Loading />,
        end : <Loading />,
        instructors : <Loading />,
        contacts : <Loading />,
        grade : <Loading />
      }, this.getData);
    }
  }

  render(){

    return(
      <tr>
        <td>{this.props.course.course_code}</td>
        <td className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>{this.state.name}</td>
        <td className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>{this.state.instructors}</td>
        <td>{this.state.start}</td>
        <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>{this.state.end}</td>
        <td className={((this.props.layout.w < 8) ? 'd-none d-print-table-cell' : '')}>{this.state.contacts}</td>
        <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>{this.state.grade}</td>
        <td>{this.state.state}</td>
      </tr>
    )
  }
}

export default CourseRow;
