import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import moment from 'moment';

class StudentAssignmentItem extends Component {
  constructor(props){
    super(props);

    this.state = {
      submitted : null,
      graded : null
    }
  }


  componentWillMount(){
    var submitted = false;
    var graded = false;
    for(var i = 0; i < this.props.submissions.length; i++){
      if(this.props.submissions[i].assignment_id === this.props.assignment.id){
        if(this.props.submissions[i].workflow_state === 'submitted'){
          submitted = true;
        }else if(this.props.submissions[i].workflow_state === 'graded'){
          submitted = true;
          graded = true;
        }
        break;
      }
    }
    this.setState({
      submitted,
      graded
    })
  }

  render(){
    return (
      <tr>
        <td>{this.props.assignment.name}</td>
        <td><Moment format="M/DD/YYYY">{moment(this.props.assignment.due_at)}</Moment></td>
        <td>{(this.state.submitted) ? <FontAwesomeIcon className='icon-green'icon="check-circle" /> : <FontAwesomeIcon className='icon-red'icon="times-circle" />}</td>
        <td>{(this.state.graded) ? <FontAwesomeIcon className='icon-green'icon="check-circle" /> : <FontAwesomeIcon className='icon-red'icon="times-circle" />}</td>
      </tr>
    )
  }
}

export default StudentAssignmentItem;
