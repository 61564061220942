import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SideBar extends Component {
  constructor(props){
    super(props);

    this.buildNavBar = this.buildNavBar.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id, name, index, type) {
    this.props.handleParentClick(id, name, index, type);
  }

  buildNavBar(item, icon, type, index){
    return(
      <NavLink key={item.id} id={item.id} name={item.course_code} active={(this.props.activeId === item.id) ? ' active' : ''} icon={icon} type={type} index={index} handleParentClick={this.handleClick}/>
    )
  }

  componentDidMount(){
  }

  render () {
    let user = "";
    if(this.props.user !== null){
      user = <UserInfo user={this.props.user} />;
    }
    //Check Active Courses
    let active = ""
    if(this.props.courses.active === null){
      active = <p>Loading...</p>
    }else if(this.props.courses.active.length === 0){
      active = <p>No Active Courses Found</p>
    }else{
      active = this.props.courses.active.map( (item, i) => this.buildNavBar(item, 'book', 'active', i) )
    }
    //Check Pending Courses
    let pending = ""
    if(this.props.courses.pending === null){
      pending = <p>Loading...</p>
    }else if(this.props.courses.pending.length === 0){
      pending = <p>No Pending Courses</p>
    }else{
      pending = this.props.courses.pending.map( (item, i) => this.buildNavBar(item, 'clock', 'pending', i) )
    }
    //Check Concluded Courses
    let concluded = ""
    if(this.props.courses.concluded === null){
      concluded = <p>Loading...</p>
    }else if(this.props.courses.concluded.length === 0){
      concluded = <p>No Concluded Courses</p>
    }else{
      concluded = this.props.courses.concluded.map( (item, i) => this.buildNavBar(item, 'lock', 'concluded', i) )
    }

    return (
      <div className="sidebar">
        <div className="sidebar-wrapper">
          {user}
          <ul className="nav nav-restrict">
            <NavLink key={0} id={0} name={"Dashboard"} active={this.props.activeId === 0 ? ' active' : ''} icon={'home'} type={'dashboard'} activeIndex={0} handleParentClick={this.handleClick} />
            <li className="nav-item"><h5>Active Courses</h5></li>
              {active}
            <li className="nav-item"><h5>Pending Courses</h5></li>
              {pending}
            <li className="nav-item"><h5>Concluded Courses</h5></li>
              {concluded}
          </ul>
          <Logo />
        </div>
      </div>
    )
  }
}

class NavLink extends Component{
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      name: this.props.name,
      type: this.props.type,
      index: this.props.index
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.props.handleParentClick(this.state.id, this.state.name, this.state.index, this.state.type);
  }

  render(){
    return(
      <li className={"nav-item" + this.props.active}>
          <div className="nav-link" onClick={this.handleClick}>
              <FontAwesomeIcon icon={this.props.icon} size="2x" pull="left"/>
              <p> {this.props.name}</p>
          </div>
      </li>
    )
  }
}

class UserInfo extends Component{
  render(){
    return(
      <React.Fragment>
        <div class="logo">
          <br class="notification hidden-sm hidden-xs"/><br class="notification hidden-sm hidden-xs"/>
        </div>
        <div class="card card-user">
  				<div class="content">
  					<div class="author">
  						<img class="avatar border-gray" src={this.props.user.avatar_url} alt={this.props.user.name + "'s Profile Image"}/>
  						<h4 class="title">{this.props.user.name}</h4>
  					</div>
  				</div>
  			</div>
      </React.Fragment>
    )
  }
}

class Logo extends Component{
  constructor(props){
    super(props);

    this.state = {
      src : "https://admindash.cpeonline.ucdavis.edu/images/admin-dash-logo.png"
    }
  }

  render(){
    return(
      <img className="admin-logo" src={this.state.src} alt="AdminDash Logo" />
    )
  }
}

export { SideBar };
