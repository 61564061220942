import React, { Component } from 'react';
import ReactLoading from 'react-loading';

class ReportLoading extends Component {
  render(){
    let className = "content-message";
    if(this.props.inline){
      className += " inline-loading"
    }
    return(
        <ReactLoading className={className} type="bubbles" height={32} width={32} color={'darkgrey'}/>
    )
  }

}

export default ReportLoading;
