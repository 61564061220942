import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CourseRow from './course_row.js';
import NoCourse from '../special/no_course.js';

class CourseReport extends Component {

  componentDidMount(){
    let footer = (
      <div>
        <FontAwesomeIcon icon="circle" color="#87CB16"/> Active | <FontAwesomeIcon icon="circle" color="#FF9500"/> Pending | <FontAwesomeIcon icon="circle" color="#00B5E2"/> Course Ended, Grades Submitted | <FontAwesomeIcon icon="circle" color="#FF4A55"/> Course Ended, Grades Not Submitted | <FontAwesomeIcon icon="circle" color="#4286f4"/> Concluded
      </div>
    )
    this.props.setFooter(footer)
  }

  render(){
    if(this.props.courses.length === 0){
      return (
        <NoCourse />
      )
    }else{
      return(
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Project Code</th>
              <th className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>Title</th>
              <th className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>Instructors</th>
              <th>Start Date</th>
              <th className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>End Date</th>
              <th className={((this.props.layout.w < 8) ? 'd-none d-print-table-cell' : '')}>Section Contact</th>
              <th className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>Grade Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {this.props.courses.map((item) => {
              return(
                <CourseRow course={item} layout={this.props.layout}/>
              )
            })}
          </tbody>
        </table>
      )
    }
  }
}

export default CourseReport;
