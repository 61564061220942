import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../loading.js';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';

class CourseReport extends Component {
  constructor(props){
    super(props);

    this.state = {
      submissions: [],
      graded: <Loading />,
      ungraded: <Loading />,
      unsubmitted: <Loading />,
      loaded: false,
      cancelToken : axios.CancelToken.source()
    }

    this.getSubmissions = this.getSubmissions.bind(this);
  }

  getSubmissions(){
    var link = '/api/reports/active/get_submissions.php';
    var data = {
      assignment: this.props.assignment.id,
      course: this.props.course
    };

    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let graded = 0;
        let ungraded = 0;
        let unsubmitted = 0;

        for(let i = 0; i < response.data.length; i++){
          if(response.data[i].workflow_state === "graded"){
    	      graded++;
    	    }else if(response.data[i].workflow_state === "submitted"){
    	      ungraded++;
    	    }else{
    	      unsubmitted++;
          }
        }

        this.setState({
          submissions: response.data,
          graded,
          ungraded,
          unsubmitted,
          loaded: true
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    this.getSubmissions();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.assignment.id !== this.props.assignment.id){
      this.setState({
        submissions : [],
        loaded: false
      }, () => this.getSubmissions());
    }
  }
  render(){
    return (
      <tr>
        <td>{this.props.assignment.name}</td>
        <td><Moment format="M/DD/YYYY h:mm A">{moment(this.props.assignment.due_at)}</Moment></td>
        <td className={((this.props.layout.w < 7) ? 'd-none d-print-table-cell' : '')}>{this.state.graded}</td>
        <td className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>{this.state.ungraded}</td>
        <td className={((this.props.layout.w < 5) ? 'd-none d-print-table-cell' : '')}>{this.state.unsubmitted}</td>
        <td>{((this.props.assignment.workflow_state === 'published') ? <FontAwesomeIcon icon="circle" color="#87CB16"/> : <FontAwesomeIcon icon="circle" color="#FF4A55"/>)}</td>
      </tr>
    )
  }

}

export default CourseReport;
