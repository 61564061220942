import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownMenu from 'react-dd-menu';
import DropDownItem from './dropdownitem.js';

class Dropdown extends Component{
  constructor(props){
    super(props)

    this.state = {
      isOpen : false
    }

    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }


  toggle(){
    this.setState({ isOpen: !this.state.isOpen })
  }

  close(){
    this.setState({ isOpen : false })
  }

  render(){
    const menuOptions = {
      isOpen: this.state.isOpen,
      close: this.close,
      upwards: true,
      align: 'right',
      toggle: <FontAwesomeIcon className="add-report-button" onClick={this.toggle} icon={['far', 'plus-square']} color="darkgrey" />,
      className: 'add-report'
    }
    if(this.props.reports.length > 0){
      return(
        <DropdownMenu {...menuOptions}>
          {this.props.reports.map((item) => {
            return(
              <DropDownItem item={item} onClick={this.props.onClick}/>
            )
          })}
        </DropdownMenu>
      )
    }else{
      return(
        <React.Fragment />
      )
    }
  }
}

export default Dropdown;
