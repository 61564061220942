import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CourseRow from './instructor_course_row.js';
import NoCourse from '../special/no_course.js';

class InstructorReport extends Component {

  componentDidMount(){
    let footer = (
      <div>
        <FontAwesomeIcon icon="circle" color="#87CB16"/> Good | <FontAwesomeIcon icon="circle" color="#FF9500"/> Concern | <FontAwesomeIcon icon="circle" color="#FF4A55"/> Bad
      </div>
    )
    this.props.setFooter(footer)
  }

  render(){
    return (
      <React.Fragment>
        {this.props.courses.map((item) => {
          return(
            <CourseRow course={item} layout={this.props.layout}/>
          )
        })}
        {
          this.props.courses.length === 0 &&
            <NoCourse />
        }
      </React.Fragment>
    )
  }

}

export default InstructorReport;
