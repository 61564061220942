import React, { Component } from 'react';

class DropDownItem extends Component{
  constructor(props){
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(){
    this.props.onClick(this.props.item.id);
  }

  render(){
    return(
      <li>
        <button onClick={this.onClick}>
          {this.props.item.title}
        </button>
      </li>
    )
  }
}

export default DropDownItem;
