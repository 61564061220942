import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './../loading.js';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';

class InstructorActivity extends Component{
  constructor(props){
    super(props);

    this.state = {
      unread : null,
      oldest  : null,
      cancelToken : axios.CancelToken.source()
    }

    this.getMessages = this.getMessages.bind(this);
  }

  getMessages(){
    var link = '/api/reports/dashboard/instructor/get_unread.php';
    if(this.props.course.workflow_state === undefined){
      this.setState({
        unread : 0,
        oldest : 'All Read'
      })
      return;
    }
    var data = {
      id: this.props.instructor.id,
      course: this.props.course.id,
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        let unread = response.data.length;
        let oldest = "All Read";
        if(unread > 0){
          oldest = response.data[unread - 1].last_message_at;
        }
        this.setState({
          unread,
          oldest
        })
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  }

  componentWillMount(){
    this.getMessages();
  }

  componentWillUnmount(){
    this.state.cancelToken.cancel();
  }

  render(){
    let overall_status = <Loading />;
    let unread_status = <Loading />;
    let oldest_status = <Loading />;

    let now = new moment();
    let unread_duration;
    if(this.state.oldest !== 'All Read'){
      unread_duration = now.diff(moment(this.state.oldest), 'days');
    }

    if(this.state.unread !== null){
      overall_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
      unread_status = <React.Fragment>{this.state.unread} <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
    }

    if(this.state.oldest !== null){
      overall_status = <FontAwesomeIcon icon="circle" color="#87CB16" />;
      if(this.state.oldest !== 'All Read'){
        oldest_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest)}</Moment> <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
      }else{
        oldest_status = <React.Fragment>{this.state.oldest} <FontAwesomeIcon icon="circle" color="#87CB16" /></React.Fragment>;
      }
    }

    if(this.state.unread > 2){
      overall_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
      unread_status = <React.Fragment>{this.state.unread} <FontAwesomeIcon icon="circle" color="#FF9500" /></React.Fragment>;
    }

    if(this.state.oldest !== 'All Read' && unread_duration > 3){
      overall_status = <FontAwesomeIcon icon="circle" color="#FF9500" />;
      oldest_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest)}</Moment> <FontAwesomeIcon icon="circle" color="#FF9500" /></React.Fragment>;
    }

    if(this.state.unread > 6){
      overall_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
      unread_status = <React.Fragment>{this.state.unread} <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>;
    }

    if(this.state.oldest !== 'All Read' && unread_duration > 6){
      overall_status = <FontAwesomeIcon icon="circle" color="#FF4A55" />;
      oldest_status = <React.Fragment><Moment format="M/DD/YYYY">{moment(this.state.oldest)}</Moment> <FontAwesomeIcon icon="circle" color="#FF4A55" /></React.Fragment>;
    }

    return(
      <React.Fragment>
        <td className={((this.props.layout.w < 6) ? 'd-print-none' : 'd-none')}>{overall_status}</td>
        <td className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>{unread_status}</td>
        <td className={((this.props.layout.w < 6) ? 'd-none d-print-table-cell' : '')}>{oldest_status}</td>
      </React.Fragment>
    )
  }
}

export default InstructorActivity;
