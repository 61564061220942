import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactToPrint from 'react-to-print';
import axios from 'axios';

//Reports
//Dashboard
import DashCoursesReport from './course_dash/course.js';
import DashInstructorsReport from './instructor/instructor.js';
import DestinyEmails from './destiny_emails/destiny_emails.js';
import CustomEmail from './custom_email';
//Active
import ActiveInstructorReport from './instructor/instructor_course.js';
import ActiveAnnouncementReport from './announcement/announcement.js';
import ActiveAssignmentReport from './assignment/assignment.js';
import ActiveStudentReport from './student/student.js'
import EmailBlaster from './email_blaster';
//Pending
import CourseReport from './course/course.js';
import RosterReport from './roster/roster.js';

class Report extends Component {
  constructor(props){
    super(props)

    this.state = {
      footer : '',
      cancelToken : axios.CancelToken.source()
    }

    this.reportRef = React.createRef();

    this.toggleReport = this.toggleReport.bind(this);
    this.getFooter = this.getFooter.bind(this);
  }

  toggleReport(){
    this.props.toggleReport(this.props.report.id);
  }

  getFooter(footer){
    this.setState({
      footer
    })
  }

  logReport(){
    var link = '/api/global/log_report.php';
    var data = {
      user_id: this.props.user.id,
      report_id: this.props.report.id,
      area: this.props.report.area
    };
    axios.post(link, data, {cancelToken: this.state.cancelToken.token})
      .then(function (response) {
        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount(){
    this.logReport();
  }

  render(){
    let report;
    switch(this.props.report.name){
      //Dashboard Reports
      case 'DashCoursesReport':
        report = <DashCoursesReport courses={this.props.target} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      case 'DashInstructorsReport':
        report = <DashInstructorsReport courses={this.props.target} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      case 'CustomEmail':
        report = <CustomEmail courses={this.props.target} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      //Active Course Reports
      case 'ActiveInstructorReport':
        report = <ActiveInstructorReport course={this.props.target} instructors={this.props.data.instructors} status={this.props.data.instructor_status} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      case 'ActiveAssignmentReport':
        report = <ActiveAssignmentReport course={this.props.target} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      case 'ActiveAnnouncementReport':
        report = <ActiveAnnouncementReport course={this.props.target} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      case 'ActiveStudentReport':
        report = <ActiveStudentReport course={this.props.target} enrollments={this.props.data.enrollments} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      case 'EmailBlaster':
        report = <EmailBlaster user={this.props.user} course={this.props.target} enrollments={this.props.data.enrollments} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      //General Repots
      case 'CourseReport':
        report = <CourseReport courses={this.props.target} data={this.props.data} type={this.props.type} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      case 'RosterReport':
        report = <RosterReport courses={this.props.target} enrollments={this.props.data.enrollments} status={this.props.data.enrollment_status} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      case 'DestinyEmails':
        report = <DestinyEmails courses={this.props.target} setFooter={this.getFooter} layout={this.props.layout} />;
        break;
      default:
        report = <React.Fragment />;
        break;
    }

    return(
      <div className="card">
				<div className="header">
          <nav className="navbar navbar-expand-lg" color-on-scroll="500">
            <div className="container-fluid">
              <span className="navbar-brand no-drag report-title"> {this.props.report.title} </span>
              <div className="collapse navbar-collapse justify-content-end">
                  <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <span className="nav-link no-drag" onClick={this.toggleReport}>
                      <FontAwesomeIcon icon="eye" />
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link no-drag">
                      <FontAwesomeIcon icon="redo" />
                    </span>
                  </li>
                  <li className="nav-item">
                    <ReactToPrint
                      trigger={() => (<span className="nav-link no-drag"><FontAwesomeIcon icon="print" /></span>)}
                      content={() => this.reportRef.current}
                    />
                  </li>
                  <li className="nav-item">
                    <span className="nav-link drag">
                      <FontAwesomeIcon icon="arrows-alt" />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className="content no-drag" ref={this.reportRef}>
					{report}
        </div>
				<div className="footer no-drag">
					{this.state.footer}
				</div>
			</div>
    )
  }

}

export default Report;
